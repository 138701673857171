.message-dialog {
     width: 100%;
     max-width: 450px;
     background: #fff;
     border-radius: 10px;
     box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
     padding: 20px;
     display: flex;
     flex-direction: column;
     align-items: center;

     .dialog-title {
          border-bottom: 1px solid lightgray;
          font-size: 18px !important;
          font-weight: 600 !important;
          text-align: center;
          margin-bottom: 10px;
          padding: 0 14px 14px;
     }

     .dialog-content {
          width: 100%;
          padding: 15px 0 !important;

          .input-wrapper {
               width: 100%;
               border: 2px solid #ccc;
               border-radius: 6px;
               display: flex;
               align-items: center;
               padding: 10px;
               transition: border 0.3s ease;

               input {
                    flex-grow: 1;
                    border: none;
                    outline: none;
                    font-size: 1rem;
                    padding: 5px;
                    width: 100%;
                    background: transparent;
               }

               &:focus-within {
                    border-color: #007bff;
               }
          }
     }

     .dialog-actions {
          width: 100%;
          display: flex;
          justify-content: space-between !important;
          padding-top: 10px;
     }

     @media (max-width: 480px) {
          max-width: 90%;
          padding: 15px;

          .dialog-title {
               font-size: 1.2rem;
          }

          .dialog-actions {
               flex-direction: column;
               gap: 10px;
          }
     }
}