/* ------------------ fonts ---------------------*/
$font-thin: 'poppinsthin';
$font-regular: 'poppinsregular';
$font-medium: 'poppinsmedium';
$font-semibold: 'poppinssemibold';
$font-bold: 'poppinsbold';
$font-black: 'poppinsblack';

/* ------------------ fonts ---------------------*/

/* ------------------ font sizes ---------------------*/


/* ------------------ font sizes ---------------------*/

/*------------------- colors ------------------------*/

/*------------------- colors ------------------------*/