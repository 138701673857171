.footer-wrapper {
  width: 100%;
  background-color: $background-color-secondary;

  .widget-info {

    li {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin-top: 10px;
    }

    .info-text {
      font-size: 16px;
      line-height: 20px;
      font-family: "Barlow", sans-serif;
      font-weight: 600;
      color: #c4c5c7;

    }
  }

  .nav-bar-info {
    .navbar-brand {
      font-size: 2rem;
    }
  }
}