/*--------------------------------

Lineicons Free Web Font
Crafted by Lineicons.com Team
Version: 4.0

-------------------------------- */

$lni-font-path: "../fonts" !default;

@font-face {
    font-family: 'lineicons';
    src: url('#{$lni-font-path}/lineicons.eot');
    src: url('#{$lni-font-path}/lineicons.eot') format('embedded-opentype'),
    url('#{$lni-font-path}/lineicons.woff2') format('woff2'),
    url('#{$lni-font-path}/lineicons.woff') format('woff'),
    url('#{$lni-font-path}/lineicons.ttf') format('truetype'),
    url('#{$lni-font-path}/lineicons.svg') format('svg');
}

/* base class */
.lni {
    display: inline-block;
    font: normal normal normal 1em/1 'lineicons';
    color: inherit;
    flex-shrink: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.lni-sm {
    font-size: 0.8em;
}

.lni-lg {
    font-size: 1.2em;
}

/* size examples - absolute units */
.lni-16 {
    font-size: 16px;
}

.lni-32 {
    font-size: 32px;
}

/* rotate the icon infinitely */
.lni-is-spinning {
    animation: lni-spin 1s infinite linear;
}

@keyframes lni-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* transform */
.lni-rotate-90 {
    transform: rotate(90deg);
}

.lni-rotate-180 {
    transform: rotate(180deg);
}

.lni-rotate-270 {
    transform: rotate(270deg);
}

.lni-flip-y {
    transform: scaleY(-1);
}

.lni-flip-x {
    transform: scaleX(-1);
}

/* icons */
$icons: (
    'zoom-out': 'ea02',
    'zoom-in': 'ea03',
    'zip': 'ea04',
    'website': 'ea05',
    'website-alt': 'ea06',
    'users': 'ea07',
    'user': 'ea08',
    'upload': 'ea09',
    'unlink': 'ea0a',
    'trash-can': 'ea0b',
    'timer': 'ea0c',
    'tab': 'ea0d',
    'star-half': 'ea0e',
    'star-fill': 'ea0f',
    'star-empty': 'ea10',
    'signal': 'ea11',
    'shortcode': 'ea12',
    'shopping-basket': 'ea13',
    'share': 'ea14',
    'share-alt': 'ea15',
    'search': 'ea16',
    'search-alt': 'ea17',
    'scroll-down': 'ea18',
    'save': 'ea19',
    'rss-feed': 'ea1a',
    'rocket': 'ea1b',
    'remove-file': 'ea1c',
    'reload': 'ea1d',
    'printer': 'ea1e',
    'plug': 'ea1f',
    'package': 'ea20',
    'mouse': 'ea21',
    'mobile': 'ea22',
    'microphone': 'ea23',
    'mic': 'ea24',
    'menu': 'ea25',
    'map': 'ea26',
    'map-marker': 'ea27',
    'magnifier': 'ea28',
    'magnet': 'ea29',
    'link': 'ea2a',
    'laptop': 'ea2b',
    'laptop-phone': 'ea2c',
    'keyboard': 'ea2d',
    'image': 'ea2e',
    'headphone': 'ea2f',
    'harddrive': 'ea30',
    'game': 'ea31',
    'folder': 'ea32',
    'files': 'ea33',
    'empty-file': 'ea34',
    'download': 'ea35',
    'domain': 'ea36',
    'display': 'ea37',
    'display-alt': 'ea38',
    'database': 'ea39',
    'dashboard': 'ea3a',
    'cut': 'ea3b',
    'control-panel': 'ea3c',
    'cogs': 'ea3d',
    'cog': 'ea3e',
    'code': 'ea3f',
    'code-alt': 'ea40',
    'cloud-upload': 'ea41',
    'cloud-sync': 'ea42',
    'cloud-network': 'ea43',
    'cloud-download': 'ea44',
    'cloud-check': 'ea45',
    'camera': 'ea46',
    'calendar': 'ea47',
    'bulb': 'ea48',
    'bookmark': 'ea49',
    'bookmark-alt': 'ea4a',
    'bluetooth': 'ea4b',
    'ban': 'ea4c',
    'archive': 'ea4d',
    'alarm': 'ea4e',
    'alarm-clock': 'ea4f',
    'add-files': 'ea50',
    'thunder': 'ea51',
    'thunder-alt': 'ea52',
    'sun': 'ea53',
    'rain': 'ea54',
    'night': 'ea55',
    'drop': 'ea56',
    'cloudy-sun': 'ea57',
    'cloud': 'ea58',
    'travel': 'ea59',
    'surf-board': 'ea5a',
    'pyramids': 'ea5b',
    'underline': 'ea5c',
    'text-format': 'ea5d',
    'text-format-remove': 'ea5e',
    'text-align-right': 'ea5f',
    'text-align-left': 'ea60',
    'text-align-justify': 'ea61',
    'text-align-center': 'ea62',
    'strikethrough': 'ea63',
    'spellcheck': 'ea64',
    'sort-amount-dsc': 'ea65',
    'sort-amount-asc': 'ea66',
    'sort-alpha-asc': 'ea67',
    'pilcrow': 'ea68',
    'pagination': 'ea69',
    'page-break': 'ea6a',
    'line-spacing': 'ea6b',
    'line-double': 'ea6c',
    'line-dotted': 'ea6d',
    'line-dashed': 'ea6e',
    'italic': 'ea6f',
    'indent-increase': 'ea70',
    'indent-decrease': 'ea71',
    'bold': 'ea72',
    'spinner': 'ea73',
    'spinner-solid': 'ea74',
    'spinner-arrow': 'ea75',
    'hourglass': 'ea76',
    'tounge': 'ea77',
    'suspect': 'ea78',
    'speechless': 'ea79',
    'smile': 'ea7a',
    'sad': 'ea7b',
    'happy': 'ea7c',
    'friendly': 'ea7d',
    'cool': 'ea7e',
    'thought': 'ea7f',
    't-shirt': 'ea80',
    'home': 'ea81',
    'gift': 'ea82',
    'cup': 'ea83',
    'crown': 'ea84',
    'briefcase': 'ea85',
    'spray': 'ea86',
    'flags-alt-1': 'ea87',
    'fireworks': 'ea88',
    'candy': 'ea89',
    'candy-cane': 'ea8a',
    'cake': 'ea8b',
    'baloon': 'ea8c',
    'trees': 'ea8d',
    'tree': 'ea8e',
    'sprout': 'ea8f',
    'mushroom': 'ea90',
    'mashroom': 'ea91',
    'leaf': 'ea92',
    'island': 'ea93',
    'flower': 'ea94',
    'bug': 'ea95',
    'volume': 'ea96',
    'volume-mute': 'ea97',
    'volume-medium': 'ea98',
    'volume-low': 'ea99',
    'volume-high': 'ea9a',
    'video': 'ea9b',
    'stop': 'ea9c',
    'shuffle': 'ea9d',
    'play': 'ea9e',
    'pause': 'ea9f',
    'gallery': 'eaa0',
    'full-screen': 'eaa1',
    'frame-expand': 'eaa2',
    'forward': 'eaa3',
    'backward': 'eaa4',
    'target-customer': 'eaa5',
    'seo': 'eaa6',
    'network': 'eaa7',
    'keyword-research': 'eaa8',
    'graph': 'eaa9',
    'bar-chart': 'eaaa',
    'warning': 'eaab',
    'unlock': 'eaac',
    'ticket': 'eaad',
    'ticket-alt': 'eaae',
    'thumbs-up': 'eaaf',
    'thumbs-down': 'eab0',
    'teabag': 'eab1',
    'shield': 'eab2',
    'radio-button': 'eab3',
    'question-circle': 'eab4',
    'protection': 'eab5',
    'power-switch': 'eab6',
    'pointer': 'eab7',
    'plus': 'eab8',
    'music': 'eab9',
    'more': 'eaba',
    'more-alt': 'eabb',
    'minus': 'eabc',
    'lock': 'eabd',
    'lock-alt': 'eabe',
    'list': 'eabf',
    'lifering': 'eac0',
    'key': 'eac1',
    'invention': 'eac2',
    'information': 'eac3',
    'infinite': 'eac4',
    'help': 'eac5',
    'heart': 'eac6',
    'heart-fill': 'eac7',
    'hand': 'eac8',
    'funnel': 'eac9',
    'flag': 'eaca',
    'flag-alt': 'eacb',
    'eye': 'eacc',
    'cross-circle': 'eacd',
    'close': 'eace',
    'circle-plus': 'eacf',
    'circle-minus': 'ead0',
    'checkmark': 'ead1',
    'checkmark-circle': 'ead2',
    'check-box': 'ead3',
    'bolt': 'ead4',
    'bolt-alt': 'ead5',
    'wheelchair': 'ead6',
    'weight': 'ead7',
    'syringe': 'ead8',
    'sthethoscope': 'ead9',
    'slim': 'eada',
    'skipping-rope': 'eadb',
    'pulse': 'eadc',
    'microscope': 'eadd',
    'hospital': 'eade',
    'heart-monitor': 'eadf',
    'first-aid': 'eae0',
    'dumbbell': 'eae1',
    'capsule': 'eae2',
    'basketball': 'eae3',
    'ambulance': 'eae4',
    'service': 'eae5',
    'restaurant': 'eae6',
    'pizza': 'eae7',
    'juice': 'eae8',
    'fresh-juice': 'eae9',
    'dinner': 'eaea',
    'coffee-cup': 'eaeb',
    'chef-hat': 'eaec',
    'burger': 'eaed',
    'write': 'eaee',
    'world': 'eaef',
    'world-alt': 'eaf0',
    'school-bench': 'eaf1',
    'school-bench-alt': 'eaf2',
    'pencil': 'eaf3',
    'pencil-alt': 'eaf4',
    'library': 'eaf5',
    'graduation': 'eaf6',
    'eraser': 'eaf7',
    'compass': 'eaf8',
    'clipboard': 'eaf9',
    'certificate': 'eafa',
    'book': 'eafb',
    'blackboard': 'eafc',
    'shift-right': 'eafd',
    'shift-left': 'eafe',
    'pointer-top': 'eaff',
    'pointer-right': 'eb00',
    'pointer-left': 'eb01',
    'pointer-down': 'eb02',
    'exit': 'eb03',
    'exit-up': 'eb04',
    'exit-down': 'eb05',
    'enter': 'eb06',
    'direction': 'eb07',
    'direction-rtl': 'eb08',
    'direction-ltr': 'eb09',
    'direction-alt': 'eb0a',
    'chevron-up': 'eb0b',
    'chevron-up-circle': 'eb0c',
    'chevron-right': 'eb0d',
    'chevron-right-circle': 'eb0e',
    'chevron-left': 'eb0f',
    'chevron-left-circle': 'eb10',
    'chevron-down': 'eb11',
    'chevron-down-circle': 'eb12',
    'arrows-vertical': 'eb13',
    'arrows-horizontal': 'eb14',
    'arrow-up': 'eb15',
    'arrow-up-circle': 'eb16',
    'arrow-top-right': 'eb17',
    'arrow-top-left': 'eb18',
    'arrow-right': 'eb19',
    'arrow-right-circle': 'eb1a',
    'arrow-left': 'eb1b',
    'arrow-left-circle': 'eb1c',
    'arrow-down': 'eb1d',
    'arrow-down-circle': 'eb1e',
    'angle-double-up': 'eb1f',
    'angle-double-right': 'eb20',
    'angle-double-left': 'eb21',
    'angle-double-down': 'eb22',
    'vector': 'eb23',
    'ux': 'eb24',
    'slice': 'eb25',
    'select-cursor': 'eb26',
    'pallet': 'eb27',
    'move': 'eb28',
    'layout': 'eb29',
    'layers': 'eb2a',
    'highlight': 'eb2b',
    'highlight-alt': 'eb2c',
    'grid': 'eb2d',
    'grid-alt': 'eb2e',
    'crop': 'eb2f',
    'brush': 'eb30',
    'brush-alt': 'eb31',
    'yen': 'eb32',
    'wallet': 'eb33',
    'rupee': 'eb34',
    'pound': 'eb35',
    'money-protection': 'eb36',
    'money-location': 'eb37',
    'euro': 'eb38',
    'dollar': 'eb39',
    'diamond-shape': 'eb3a',
    'diamond-alt': 'eb3b',
    'credit-cards': 'eb3c',
    'coin': 'eb3d',
    'calculator': 'eb3e',
    'calculator-alt': 'eb3f',
    'wheelbarrow': 'eb40',
    'trowel': 'eb41',
    'shovel': 'eb42',
    'ruler': 'eb43',
    'ruler-pencil': 'eb44',
    'ruler-alt': 'eb45',
    'paint-roller': 'eb46',
    'paint-bucket-alt': 'eb47',
    'helmet': 'eb48',
    'hammer': 'eb49',
    'construction': 'eb4a',
    'construction-hammer': 'eb4b',
    'bricks': 'eb4c',
    'train': 'eb4d',
    'train-alt': 'eb4e',
    'taxi': 'eb4f',
    'support': 'eb50',
    'ship': 'eb51',
    'scooter': 'eb52',
    'road': 'eb53',
    'reply': 'eb54',
    'postcard': 'eb55',
    'popup': 'eb56',
    'plane': 'eb57',
    'phone': 'eb58',
    'phone-set': 'eb59',
    'inbox': 'eb5a',
    'helicopter': 'eb5b',
    'envelope': 'eb5c',
    'comments': 'eb5d',
    'comments-reply': 'eb5e',
    'comments-alt-2': 'eb5f',
    'caravan': 'eb60',
    'car': 'eb61',
    'car-alt': 'eb62',
    'bus': 'eb63',
    'bullhorn': 'eb64',
    'bubble': 'eb65',
    'bridge': 'eb66',
    'bi-cycle': 'eb67',
    'anchor': 'eb68',
    'target': 'eb69',
    'target-revenue': 'eb6a',
    'tag': 'eb6b',
    'stats-up': 'eb6c',
    'stats-down': 'eb6d',
    'stamp': 'eb6e',
    'revenue': 'eb6f',
    'quotation': 'eb70',
    'pin': 'eb71',
    'pie-chart': 'eb72',
    'paperclip': 'eb73',
    'offer': 'eb74',
    'notepad': 'eb75',
    'license': 'eb76',
    'investment': 'eb77',
    'invest-monitor': 'eb78',
    'handshake': 'eb79',
    'grow': 'eb7a',
    'delivery': 'eb7b',
    'customer': 'eb7c',
    'consulting': 'eb7d',
    'cart': 'eb7e',
    'cart-full': 'eb7f',
    'briefcase-alt': 'eb80',
    'apartment': 'eb81',
    'agenda': 'eb82',
    'zoom': 'eb83',
    'zapier': 'eb84',
    'youtube': 'eb85',
    'ycombinator': 'eb86',
    'yahoo': 'eb87',
    'xrp': 'eb88',
    'xbox': 'eb89',
    'wordpress': 'eb8a',
    'wordpress-fill': 'eb8b',
    'windows': 'eb8c',
    'whatsapp': 'eb8d',
    'wechat': 'eb8e',
    'webhooks': 'eb8f',
    'vuejs': 'eb90',
    'vs-code': 'eb91',
    'volkswagen': 'eb92',
    'vmware': 'eb93',
    'vk': 'eb94',
    'visa': 'eb95',
    'vimeo': 'eb96',
    'vercel': 'eb97',
    'unsplash': 'eb98',
    'ubuntu': 'eb99',
    'typescript': 'eb9a',
    'twitter': 'eb9b',
    'twitter-original': 'eb9c',
    'twitter-fill': 'eb9d',
    'twitch': 'eb9e',
    'tumblr': 'eb9f',
    'trello': 'eba0',
    'toyota': 'eba1',
    'tiktok': 'eba2',
    'tiktok-alt': 'eba3',
    'tesla': 'eba4',
    'telegram': 'eba5',
    'telegram-original': 'eba6',
    'tailwindcss': 'eba7',
    'swift': 'eba8',
    'svelte': 'eba9',
    'stumbleupon': 'ebaa',
    'stripe': 'ebab',
    'steam': 'ebac',
    'stackoverflow': 'ebad',
    'squarespace': 'ebae',
    'spotify': 'ebaf',
    'spotify-original': 'ebb0',
    'soundcloud': 'ebb1',
    'soundcloud-original': 'ebb2',
    'snapchat': 'ebb3',
    'slideshare': 'ebb4',
    'slack': 'ebb5',
    'slack-line': 'ebb6',
    'skype': 'ebb7',
    'sketch': 'ebb8',
    'signal-app': 'ebb9',
    'shopify': 'ebba',
    'safari': 'ebbb',
    'reddit': 'ebbc',
    'react': 'ebbd',
    'quora': 'ebbe',
    'python': 'ebbf',
    'producthunt': 'ebc0',
    'postman': 'ebc1',
    'postgresql': 'ebc2',
    'playstation': 'ebc3',
    'play-store': 'ebc4',
    'play-store-fill': 'ebc5',
    'play-store-alt': 'ebc6',
    'pinterest': 'ebc7',
    'php': 'ebc8',
    'paypal': 'ebc9',
    'paypal-original': 'ebca',
    'patreon': 'ebcb',
    'paddle': 'ebcc',
    'opera': 'ebcd',
    'oculus': 'ebce',
    'npm': 'ebcf',
    'notion': 'ebd0',
    'nodejs': 'ebd1',
    'nodejs-alt': 'ebd2',
    'nissan': 'ebd3',
    'nextjs': 'ebd4',
    'netlify': 'ebd5',
    'netflix': 'ebd6',
    'mysql': 'ebd7',
    'mongodb': 'ebd8',
    'microsoft': 'ebd9',
    'microsoft-teams': 'ebda',
    'microsoft-edge': 'ebdb',
    'meta': 'ebdc',
    'meta-2': 'ebdd',
    'meta-1': 'ebde',
    'mercedes': 'ebdf',
    'medium': 'ebe0',
    'medium-alt': 'ebe1',
    'mastercard': 'ebe2',
    'markdown': 'ebe3',
    'mailchimp': 'ebe4',
    'magento': 'ebe5',
    'linkedin': 'ebe6',
    'linkedin-original': 'ebe7',
    'lineicons': 'ebe8',
    'lineicons-symbol': 'ebe9',
    'lineicons-symbol-alt': 'ebea',
    'lineicons-symbol-alt-2': 'ebeb',
    'lineicons-alt': 'ebec',
    'line': 'ebed',
    'laravel': 'ebee',
    'kubernetes': 'ebef',
    'jsfiddle': 'ebf0',
    'joomla': 'ebf1',
    'joomla-original': 'ebf2',
    'jcb': 'ebf3',
    'javascript': 'ebf4',
    'java': 'ebf5',
    'jamstack': 'ebf6',
    'jaguar': 'ebf7',
    'ios': 'ebf8',
    'instagram': 'ebf9',
    'instagram-original': 'ebfa',
    'instagram-fill': 'ebfb',
    'imdb': 'ebfc',
    'html5': 'ebfd',
    'hacker-news': 'ebfe',
    'grammarly': 'ebff',
    'google': 'ec00',
    'google-wallet': 'ec01',
    'google-pay': 'ec02',
    'google-drive': 'ec03',
    'google-cloud': 'ec04',
    'google-meet': 'ec05',
    'goodreads': 'ec06',
    'go': 'ec07',
    'github': 'ec08',
    'github-original': 'ec09',
    'git': 'ec0a',
    'gatsby': 'ec0b',
    'ford': 'ec0c',
    'flutter': 'ec0d',
    'flickr': 'ec0e',
    'fitbit': 'ec0f',
    'firefox': 'ec10',
    'firefox-original': 'ec11',
    'firebase': 'ec12',
    'figma': 'ec13',
    'facetime': 'ec14',
    'facebook': 'ec15',
    'facebook-oval': 'ec16',
    'facebook-original': 'ec17',
    'facebook-messenger': 'ec18',
    'facebook-line': 'ec19',
    'facebook-fill': 'ec1a',
    'ethereum': 'ec1b',
    'envato': 'ec1c',
    'edge': 'ec1d',
    'drupal': 'ec1e',
    'drupal-original': 'ec1f',
    'dropbox': 'ec20',
    'dropbox-original': 'ec21',
    'dribbble': 'ec22',
    'docker': 'ec23',
    'discover': 'ec24',
    'discord': 'ec25',
    'discord-alt': 'ec26',
    'diners-club': 'ec27',
    'digitalocean': 'ec28',
    'dialogflow': 'ec29',
    'dev': 'ec2a',
    'css3': 'ec2b',
    'creative-commons': 'ec2c',
    'cpanel': 'ec2d',
    'coral': 'ec2e',
    'connectdevelop': 'ec2f',
    'coinbase': 'ec30',
    'codepen': 'ec31',
    'cloudflare': 'ec32',
    'cisco': 'ec33',
    'chromecast': 'ec34',
    'chrome': 'ec35',
    'cash-app': 'ec36',
    'btc': 'ec37',
    'brave': 'ec38',
    'bootstrap': 'ec39',
    'bootstrap-5': 'ec3a',
    'bmw': 'ec3b',
    'bluetooth-original': 'ec3c',
    'blogger': 'ec3d',
    'bittorrent': 'ec3e',
    'bitcoin': 'ec3f',
    'bitbucket': 'ec40',
    'behance': 'ec41',
    'behance-original': 'ec42',
    'azure': 'ec43',
    'aws': 'ec44',
    'audi': 'ec45',
    'atlassian': 'ec46',
    'apple-pay': 'ec47',
    'apple-music': 'ec48',
    'apple-brand': 'ec49',
    'app-store': 'ec4a',
    'angular': 'ec4b',
    'angellist': 'ec4c',
    'android': 'ec4d',
    'android-original': 'ec4e',
    'amex': 'ec4f',
    'amazon': 'ec50',
    'amazon-pay': 'ec51',
    'amazon-original': 'ec52',
    'airtable': 'ec53',
    'airbnb': 'ec54',
    'adobe': 'ec55',
    '500px': 'ec56',
);

@function unicode($str) {
    @return unquote("\"\\#{$str}\"");
}

@each $name,
$code in $icons {
    .lni-#{""+$name}::before {
        content: unicode($code);
    }
}