.categories-wrapper {
  .col-sm-4 {
    margin-bottom: 30px;
  }
}

a.categories {
  padding: 10px;
  border-radius: 10px;
  background: $white;
  color: $text-color;
  display: block;
  text-decoration: none;
  border: 1px solid $grey-opacity;

  img {
    width: 100%;
  }

  .content {
    padding: 10px 0 0;

    h4,
    p {
      text-decoration: none;
    }

    h4 {
      color: $primary-color;
    }

    p {
      margin: 0;
    }

    .btn {
      margin: 0;
    }
  }

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 300ms;
  }
}

.landing-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .landing-content {
    background-color: $white;
    display: flex;
    flex-direction: row;
    margin: 0px !important;
    padding: 0px !important;

    .landing-image {
      height: 100%;
      width: 100%;
      flex: 1;
    }

    .landing-text {
      flex: 1;
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;

      .landing-text-title {
        font-size: 2rem;
        width: 100%;
        text-align: center;
        color: orangered;
      }
    }
  }
}



/* New SCSS */
.landing-page-main-section {
  background-color: $white;
  padding: 0 12px;

  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  @media screen and (min-width: 768px) {
    padding: 0;
    overflow: hidden;

    h1 {
      font-size: 40px;
    }
  }

  .landing-page-image {
    width: 80%;
  }

  .scrollable-container-active-opp {
    background: $color-sky-blue-light-dark;
    overflow: hidden;

    .scroll-wrapper {
      display: flex;
      gap: 40px;
      animation: scroll 20s linear infinite;
    }

    .scroll-item {
      flex: 0 0 auto;
      padding: 16px 0;
      font-size: 24px;
      font-weight: 700;
      color: $white;

      .asterisk {
        margin-right: 5px;
      }
    }

    /* Animation */
    @keyframes scroll {
      0% {
        transform: translateX(100%);
      }

      100% {
        transform: translateX(-100%);
      }
    }
  }

  .sub-text {
    font-weight: 500;
  }

  .join-signup-btn {
    background-color: $color-sky-blue-light-dark;
    color: $white;
    border-radius: 34px !important;
    padding: 6px 20px;
    font-weight: 500;
  }

  .section-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    text-align: center;

    .content {
      max-width: 756px;

      h1 {
        p {
          color: $color-sky-blue-light-dark;
        }
      }
    }
  }

  .section-two {
    max-width: 1200px;
    margin: 0 auto;
  }

  .section-three {
    border-radius: 32px;
    background-color: $color-blue-light-violet;

    .right-content {
      .sub-text {
        span {
          font-weight: 600;
        }
      }

      .list-items {
        padding-right: inherit;
      }
    }
  }

  .section-four {
    h1 {
      text-align: center;
    }

    .tabs-container {
      display: flex;
      justify-content: space-evenly;
      margin: 20px auto;
      background-color: $color-gray-light-gray;
      border-radius: 30px;
      max-width: 1200px;

      .tab {
        padding: 10px 20px;
        margin: 5px;
        border: none;
        border-radius: 30px;
        background-color: transparent;
        color: $color-sky-blue-light-dark;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        &.active {
          background-color: $color-sky-blue-light-dark;
          color: $white;
        }

        &:hover {
          background-color: $color-sky-blue-light-dark;
          color: $white;
        }
      }
    }

    .cards-container {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      max-width: 1200px;
      margin: 0 auto;

      .card {
        background-color: $color-blue-light-violet;
        border-radius: 15px;
        border: none;
        padding: 20px;
        margin: 10px;
        width: 200px;
        text-align: center;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s;

        .icon {
          font-size: 30px;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 18px;
          margin-bottom: 10px;
          color: $color-sky-blue-light-dark;
        }

        p {
          font-size: 14px;
          font-weight: 500;
        }

        &:hover {
          transform: translateY(-5px);
        }
      }
    }

    /* Mobile Responsiveness */
    @media (max-width: 768px) {
      .tabs-container {
        flex-direction: column;
        padding: 5px;
      }

      .card {
        width: 100%;
        max-width: 300px;
      }
    }
  }

  .section-five {
    text-align: center;
    padding: 40px 20px;
    border-radius: 32px;
    background-color: $color-blue-light-violet;

    h1 {
      text-align: center;
    }

    .features-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 48px;
      max-width: 1100px;
      margin: 0 auto;

      .feature-card {
        width: 300px;
        background-color: $white;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

        &:hover {
          transform: translateY(-5px);
        }

        .content {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .icon {
            width: 30px;
            height: 30px;
            margin-right: 12px;
          }

          h3 {
            font-size: 18px;
            font-weight: 600;
            width: 50%;
          }
        }

        p {
          font-size: 14px;
          font-weight: 500;
          margin-top: 5px;
        }
      }
    }

    /* Responsive for Mobile */
    @media (max-width: 768px) {
      .features-container {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .section-six {
    .left-content {
      padding: inherit;
    }
  }

  .section-seven {
    .blue-bg {
      border-radius: 32px 32px 0 0;
      background-color: $color-sky-blue-light-dark;

      h1 {
        color: $white;
      }

      .content {
        max-width: 620px;
        margin: 0 auto;
        color: $white;

        .sub-text {
          opacity: 80%;
        }
      }

      .signup-btn {
        color: $color-sky-blue-light-dark;
        background-color: $white;
        border-radius: 34px !important;
        padding: 6px 20px;
        font-weight: 500;
      }
    }
  }
}