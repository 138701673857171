.shortlist-bottom-sheet-wrapper {
  height: 100%;
  min-height: 400px;
  position: relative;

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
    line-height: 50px;
    height: 50px;
  }

  .content {
    position: absolute;
    overflow-y: auto;
    height: calc(100% - 100px);
    top: 50px;
    width: 100%;
  }

  .footer {
    height: 50px;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}