// .recruiter-dashboard-wrapper {
//   display: flex;
//   flex-direction: row;
//   padding: 20px;
//   width: 100%;
//   justify-content: center;

//   .option-card-wrapper {
//     min-height: 200px;
//     width: 100%;
//     background-color: white;
//     padding: 20px;
//     justify-content: center;
//     border: 1px solid rgb(245, 245, 245);
//     display: flex;
//     flex-direction: column;


//     &:hover {
//       box-shadow: rgb(76, 64, 237, .2) 0px 7px 29px 0px;
//     }

//     h4 {
//       text-align: center;
//     }

//     p {
//       text-align: center;
//     }
//   }
// }

.industry-profile-dashboard-wrapper {
  height: 100%;
  min-height: 100%;

  .first,
  .second,
  .third,
  .fourth,
  .fiveth,
  .last {
    background-color: white;

    .option-card-wrapper {
      height: 100%;
      width: 100%;

      padding: 20px;
      justify-content: center;
      border: 1px solid rgb(214, 214, 214);
      display: flex;
      flex-direction: column;
      cursor: pointer;

    }


    &:hover {
      box-shadow: rgb(76, 64, 237, .2) 0px 7px 29px 0px;
    }

    h4 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  .first {
    float: left;
    width: 30%;
    height: 40%;

  }

  .second {
    float: left;
    width: 30%;
    height: 60%;
    background-image: url('/assets/images/section-6.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-color: white;
  }


  .third {
    float: right;
    width: 35%;
    height: 70%;
    // background-image: url('/assets/images/section-3.png');
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position-y: bottom;
    // background-color: white;
  }

  .fiveth {
    float: right;
    width: 35%;
    height: 70%;
    // background-image: url('/assets/images/section-3.png');
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position-y: bottom;
    // background-color: white;
  }

  .fourth {
    float: right;
    width: 35%;
    height: 30%;
  }

  .last {
    float: right;
    width: 35%;
    height: 30%;
  }
}

.new-recruiter-design {
  display: flex;
  max-width: 1280px;
  margin: auto;
  overflow: hidden;
  padding: 20px;
  position: relative;

  // Sidebar for Desktop
  .sidebar {
    display: flex;
    flex-direction: column;
    width: 220px;
    padding: 20px;
    background: #1e3a8a;
    color: white;
    border-radius: 12px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

    .section-title {
      font-size: 18px;
      font-weight: bold;
      padding: 12px 15px;
      margin-bottom: 10px;
      background: linear-gradient(to right, #3b82f6, #1e40af);
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease;
      text-align: left;

      &:hover {
        background: linear-gradient(to right, #60a5fa, #2563eb);
      }

      &.active {
        background: #60a5fa;
        color: #fff;
      }
    }
  }

  // Mobile Menu Icon (Right Arrow)
  .menu-icon {
    display: none; // Default hidden for desktop
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 24px;
    background: #1e3a8a;
    color: white;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    border: none;
  }

  // Mobile Sidebar (Hidden by Default)
  .mobile-sidebar {
    display: none; // Hidden by default
    position: absolute;
    top: 50px;
    right: 10px;
    background: #1e3a8a;
    color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 200px;
  }

  .mobile-sidebar.open {
    display: block;
  }

  .mobile-sidebar .section-title {
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    background: #3b82f6;
    border-radius: 6px;

    &:hover {
      background: #2563eb;
    }

    &.active {
      background: #60a5fa;
      color: #fff;
    }
  }

  // Content Area
  .content {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // ** Correct Grid Layout for Tiles **
  .tiles-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); // Responsive grid
    gap: 15px;
    margin-top: 20px;
    width: 100%;
    max-width: 900px; // Ensures proper alignment

    .tile {
      background: white;
      padding: 20px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: #1e3a8a;
      border-radius: 10px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 120px;

      &:hover {
        transform: scale(1.05);
      }
    }

    .highlight-tile {
      background: #22c55e; // Green background for distinction
      color: white;

      &:hover {
        background: #16a34a; // Darker green on hover
      }
    }

  }

  // Mobile View Adjustments
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    position: relative;

    .sidebar {
      display: none; // Hide sidebar in mobile
    }

    .menu-icon {
      display: block; // Show menu icon in mobile
    }

    .content {
      width: 100%;

      .tiles-container {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); // Ensures side-by-side alignment
        gap: 10px;
        justify-content: center;
      }
    }
  }
}

.verification-message {
  max-width: 70%;
  margin: 20px auto;
  padding: 20px;
  background: $white;
  border-left: 5px solid $color-sky-blue-light-dark;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: justify;

  .main-message {
    font-size: 1.1rem;
    font-weight: 500;
    color: $color-black-dark;
    margin-bottom: 10px;
  }

  .sub-message {
    font-size: 1rem;
    color: $color-gray-dark;
  }

  @media (max-width: 768px) {
    padding: 15px;
    max-width: 90%;

    .main-message {
      font-size: 1rem;
    }

    .sub-message {
      font-size: 0.9rem;
    }
  }
}