.custom-tabs {
  .tabs {
    overflow: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;



  }

  .tabs li {
    float: left;
    margin: 0 .5em 0 0;
    width: 100%;

    &:first-child {
      padding: 0px 0px 0px 20px;
    }

    &:last-child {
      padding: 0px 20px 0px 0px;
    }


  }

  .tabs a {
    position: relative;
    background: #ffffff;
    background-image: linear-gradient(to bottom, #fff, #ddd);
    padding: .7em 3.5em;
    float: left;
    text-decoration: none;
    color: #444;
    font-weight: 500;
    border-radius: 5px 0 0 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .4);
    width: 100%;
    text-align: center;
  }

  .tabs a:hover,
  .tabs a:hover::after,
  .tabs a:focus,
  .tabs a:focus::after {
    background: #fff;
  }

  .tabs a:focus {
    outline: 0;
  }

  .tabs a::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: -.5em;
    bottom: 0;
    width: 1em;
    background: #ddd;
    background-image: linear-gradient(to bottom, #fff, #ddd);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .4);
    // transform: skew(10deg);
    border-radius: 0 5px 0 0;
  }

  .tabs .active a,
  .tabs .active a::after {
    background: $primary-color;
    z-index: 3;
    color: #fff;
  }

}