.personal-details-wrapper {
  &.personal-details-edit-mode {
    .background-profile-image {
      background-color: #e5e5f7;
      background-image: radial-gradient(#999df6 0.65px, transparent 0.65px), radial-gradient(#999df6 0.65px, #e5e5f7 0.65px);
      background-size: 26px 26px;
      background-position: 0 0, 13px 13px;
      padding: 10px;
      margin: 10px 0px;

      .profile-pic-icon {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          border: 1px solid #4a90e2;
          object-fit: cover;
        }
      }

      .upload-div {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;

        .action-btns-div {
          z-index: 9999;
        }
      }
    }
  }
}

.personal-details-display-mode {
  .common-bg-transparent {
    background: #00000000;
    padding: 15px;
    border-radius: 150px 10px 10px 150px;
    margin: 50px 0 40px 0;

    .edit-mode-icon {
      display: flex;
      justify-content: flex-end;
    }

    .profile-data-details {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .profile-pic-icon {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 270px;
        height: 210px;
        top: -45px;

        .progress-circle {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          &:before {
            content: '';
            position: absolute;
            top: -10px;
            left: -10px;
            right: -10px;
            bottom: -10px;
            border-radius: 50%;
            background: conic-gradient(#4a90e2 calc(var(--progress, 0) * 1%),
                transparent calc(var(--progress, 0) * 1%));
            z-index: 0;
            transform: rotate(180deg);
            background-clip: content-box;
            border: 3px solid transparent;
            transition: all 300ms;
          }
        }

        img {
          width: 300px;
          height: 300px;
          border-radius: 50%;
          z-index: 1;
          border: 1px solid #4a90e2;
          object-fit: cover;
        }

        .edit-icon {
          position: absolute;
          bottom: 34px;
          right: 30%;
          z-index: 1;

          transform: translate(80px, 84px);
          background: #fff;
          border-radius: 50%;
          border: 1px solid black;
          padding: 5px;
          font-size: 0;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        }

        .profile-progress-bar {
          position: absolute;
          bottom: -25px;
          right: 10%;
          z-index: 1;
          width: 60px;
          height: 60px;
          background: #ffffff00;
          border-radius: 30px;
          padding: 5px;
          font-size: 12px;
          justify-content: center;
          transform: translate(-45px, 82px);
          display: flex;
          flex-direction: row;
          text-align: center;
          justify-content: flex-end;
          align-items: flex-end;

          img {
            width: 40px;
            height: 40px;
            border-radius: 0% !important;
            z-index: 1;
            border: none !important;
          }
        }
      }

      .profile-info-content {

        h2 {
          margin: 10px 0 5px;
          font-size: 1.5rem;
          color: #333;
        }

        p {
          margin: 5px 0;
          color: #777;
        }
      }

      .contact-info {
        .contact-details {
          display: flex;
          align-items: center;

          mat-icon {
            margin-right: 10px;
          }
        }
      }
    }
  }
}



//Media Queries for responsive

@media (min-width: 1024px) and (max-width: 1200px) {
  .personal-details-display-mode {
    .common-bg {
      .profile-data-details {
        .pd-lt-64 {
          padding-left: 64px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .personal-details-display-mode {
    .common-bg-transparent {
      border-radius: 10px;
    }

    .common-bg {
      .profile-data-details {
        flex-direction: column;
        align-items: center;

        .profile-pic-icon {
          width: auto;
          height: auto;
          top: 0;
          margin-bottom: 10px;

          img {
            width: 160px;
            height: 160px;
            object-fit: cover;
          }

          .profile-progress-bar {
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
          }

          .edit-icon {
            bottom: auto;
            right: auto;
          }
        }

        .profile-info-content {
          margin-left: 0;
          text-align: center;

          h2 {
            font-size: 1.2em;
          }

          p {
            font-size: 0.9em;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .personal-details-display-mode {
    .common-bg {
      .profile-data-details {
        flex-direction: column;
        align-items: center;

        .profile-info-content {
          h2 {
            font-size: 1em;
          }

          p {
            font-size: 0.8em;
          }
        }
      }
    }
  }
}