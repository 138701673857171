.jobs-created-applied-container {
  .applied-profile-card-wrapper {
    @media (min-width: 576px) {
      height: 100%;
    }

    .applied-profile-card {
      display: flex;
      flex-direction: column;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      margin: 0 auto 20px;
      transition: all 0.3s ease;
      height: 100%;

      .applied-profile-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      .applied-profile-image {
        flex-shrink: 0;
        width: 100%;
        height: 272px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0;
        }
      }

      .applied-profile-info {
        flex-grow: 1;
        padding: 12px;

        .applied-profile-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .applied-profile-role {
            color: $color-green-light-parrot;
            font-weight: 500;
          }

          .applied-profile-actions {
            display: flex;
            gap: 10px;
          }
        }

        .applied-profile-name {
          font-weight: 600;
          margin: 8px 0;
          font-size: 1rem;

          @media (min-width: 768px) {
            font-size: 1rem;
          }
        }

        .details-and-button {
          margin-bottom: 10px;
          font-size: .8rem;
        }

        .applied-profile-card-btns {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;

          .btn {

            &.apply-sigin-view-btn,
            &.btn-danger {
              padding: 6px 16px;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }

        .applied-profile-footer {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .applied-profile-location,
          .applied-profile-skills {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: flex-start;
            font-size: .8rem;

            mat-icon {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }

  .applied-profile-col {
    @media (min-width: 576px) and (max-width: 992px) {
      flex: 0 0 50%;
    }
  }
}