.chat {
  /* Chat styles */
  border: 1px solid $color-gray-light;
  height: 100%;
  position: relative;

  .chat-header {
    padding: 17px;
    border-bottom: 1px solid $color-gray-light; // Darker border color
    height: 80px;
    position: absolute;
    top: 0;
    width: 100%;

    .user-details {
      display: flex;
      align-items: center;
    }

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .name-lastseen {
      display: flex;
      flex-direction: column;
    }

    .name {
      font-weight: bold;
      color: $color-black-darker; // White text color
    }

    .lastseen {
      font-size: 12px;
      color: $color-blue-light-gray; // Lighter text color
    }
  }


  .chat-messages-background {
    background-image: url('/assets/images/section-7.png');
    background-size: cover;
    height: calc(100% - 160px);
    overflow-y: scroll;
    position: absolute;
    top: 80px;
    width: 100%;

    .chat-messages {
      display: flex;
      flex-direction: column;
      padding: 10px;
      overflow-y: auto;
      height: 100%;

      background-color: rgba(0, 0, 0, 0.3);





      /* Set a maximum height for the container */
      .message {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;

        &.sent {
          align-self: flex-end;
          text-align: right;

          .message-content {
            background-color: $color-white; // Background color for sent messages
            color: $color-gray-dark; // Text color for sent messages
          }

          .message-info {
            flex-direction: row-reverse;
            /* Reverse the order of elements for sent messages */
          }

          .message-name {
            margin-left: 10px;
          }
        }

        &.received {
          align-self: flex-start;
          text-align: left;

          .message-content {
            background-color: $color-white; // Background color for received messages
            color: $color-gray-dark; // Text color for received messages
          }

          .message-name {
            margin-right: auto;
          }

          .message-timestamp {
            margin-left: auto;
          }

          .message-name {
            margin-right: 10px;
          }
        }

        .message-content {
          padding: 10px;
          border-radius: 10px;
          max-width: 100%; // Example maximum width for message content
          word-wrap: break-word; // Allow long words to wrap
          min-width: 100px; // Set minimum width to prevent very narrow boxes
          margin-bottom: 10px;
        }

        .message-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .message-name {
          margin-right: 5px;
          font-size: 0.7rem;
        }

        .message-timestamp {
          font-size: 0.7rem;
          color: #f57c00;
          font-size: 12px;
          margin-top: 5px;
          display: inline-block;
          text-align: center;
        }
      }
    }
  }


  .message-input {
    height: 80px;
    position: absolute;
    bottom: 0;
    width: 100%;

    .message-bar {
      display: flex;
      align-items: center;
      padding: 10px;
    }

    .message-bar span {
      margin-right: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      /* Make icons circular */
      background-color: $color-sky-blue;
      /* Blue icon color */
    }

    .message-bar span mat-icon {
      color: $color-white;
      /* White icon color */
    }

    .input-bar {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $grey;
      border-radius: 20px;
      padding: 5px 10px;
    }

    .input-bar input {
      flex: 1;
      border: none;
      outline: none;
      background-color: transparent;
      /* Transparent background */
      // color: $color-white;
      color: $color-black-darker;
      /* White text color */
    }

    .input-bar span {
      margin-left: 10px;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      .message-bar span {
        width: 30px;
        height: 30px;
        font-size: 18px;
      }

      .input-bar {
        padding: 3px 5px;
      }
    }
  }

}

.wrapper {
  &.wrapper-text {
    text-align: center;
    font-size: 1.5rem;
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;

    .row {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    text-align: center;
    // padding: 100px 0;
    font-size: 1.5rem;
  }
}