.container-fluid {

  &.chat-container {
    height: 100%;
    width: 100%;
  }

  .row {
    height: 100%;

    @media (max-width: 768px) {
      height: 968px;
    }
  }

  .chat-list-left {
    padding: 0;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  .chat-open-right {
    padding-left: 0;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .chat-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .chats-title {
      font-size: 18px;
      font-weight: bold;
    }

    .icons-container {
      display: flex;
    }

    //  chat icons edit and more styles
    .icons-container span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      /* Grey background color */
      margin-left: 10px;
      /* Adjust spacing between icons */
    }

    .icons-container span mat-icon {
      color: $color-white;
      /* White icon color */
    }
  }


  .search-bar {
    width: 100%;
    height: 80px;
    position: relative;
    display: inline-block;
    padding: 16px 24px;
    border: 1px solid $color-gray-light;

    input[type="text"] {
      width: 100%;
      padding-left: 58px;
      border: 1px solid #aaa;
      border-radius: 30px;
      background-color: #E9E9E9;
      outline: none;
      height: 52px;
      box-sizing: border-box; // Include padding and border in width calculation
    }

    mat-icon {
      position: absolute;
      top: 50%;
      left: 42px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    input::placeholder {
      color: #888;
    }
  }



  //  Message Inbox styles
  .inbox-text-container {
    .inbox-text {
      color: $color-white;
      padding: 8px 15px;
      border-radius: 20px;
    }
  }

}