@font-face {
    font-family: 'poppinsblack';
    src: url('../fonts/poppins-black-webfont.woff2') format('woff2'),
        url('../fonts/poppins-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold-webfont.woff2') format('woff2'),
        url('../fonts/poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinslight';
    src: url('../fonts/poppins-light-webfont.woff2') format('woff2'),
        url('../fonts/poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsmedium';
    src: url('../fonts/poppins-medium-webfont.woff2') format('woff2'),
        url('../fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular-webfont.woff2') format('woff2'),
        url('../fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinssemibold';
    src: url('../fonts/poppins-semibold-webfont.woff2') format('woff2'),
        url('../fonts/poppins-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsthin';
    src: url('../fonts/poppins-thin-webfont.woff2') format('woff2'),
        url('../fonts/poppins-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}