.user-dashboard-wrapper {
  // Need to remove after checking all pages
  // .upload-div {
  //   height: 150px;
  //   width: 150px;
  //   margin: auto;
  // }

  // .upload-div img {
  //   height: 150px;
  //   width: 150px;
  //   object-fit: cover;
  // }

  // .upload-div .label-profile-upload {
  //   height: 150px;
  //   width: 150px;
  // }

  // .profile-name {
  //   text-align: center;
  //   font-size: 1.2rem;

  //   &:first-letter {
  //     text-transform: uppercase;
  //   }
  // }

  // .profile-bio {
  //   text-align: center;
  // }

  .chips-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-chip {
      height: 34px;
      border-radius: 17px;
      background-color: $accent-color;
      color: $white;
      border: 1px solid $accent-color;
      padding: 0px 10px;

      &.active {
        background-color: $white ;
        color: $accent-color;
      }
    }
  }

  /* New Screens Scss */
  .dashboard-profile-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .profile-image {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      /* margin-top: 15px; */
      margin-bottom: -20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .profile-details {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;

      .profile-name {
        font-weight: bold;
        font-size: 1rem;
        margin: 0;
      }

      .profile-role {
        font-size: 14px;
        color: #666;
        margin: 0;
      }
    }

    .dashboard-links {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;

      &.wrapper-card {
        padding: 10px 20px;
      }

      .dashboard-link {
        display: flex;

        &:first-child {
          margin-bottom: 5px;
        }

        mat-icon {
          font-size: 20px;
        }

        p {
          margin-bottom: 0rem;
        }
      }

      &.applied-bookmarked-endorsed {
        .applied-opportunities {
          .applied-opportunities-icon {
            margin-right: 10px;
          }
        }

        .bookmarked-opportunities {
          align-items: center;

          .bookmarked-opportunities-icon {
            height: 24px !important;
            width: 24px !important;
            margin-right: 10px;
          }
        }

        .endorsements {
          align-items: center;

          .endorsements-icon {
            height: 28px !important;
            width: 28px !important;
            margin-right: 8px;
          }
        }
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .profile-image {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  .jobs-section {
    .job-search-container {
      background-color: #3632F1;
      color: #fff;
      padding: 20px;
      border-radius: 12px;

      .search-header {
        h3 {
          font-size: 28px;
        }

        p {
          font-size: 1rem;
        }
      }

      .search-bar {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 20px;

        .search-input {
          height: 50px;
          flex: 1;
          padding-left: 45px;
          border: none;
          border-radius: 5px 0 0 5px !important;
          font-size: 1rem;
        }

        .search-icon {
          position: absolute;
          left: 15px;
          top: 58%;
          transform: translateY(-50%);
          font-size: 24px;
          color: grey;
        }

        .sort-dropdown {
          height: 50px;
          background-color: white;
          padding: 3px;
          border-radius: 0 5px 5px 0;

          .ng-select-container {
            height: 44px;
            border: none;
            border-radius: 8px;
            background-color: #47B165;
          }

          .ng-select-placeholder {
            padding-left: 10px;
            text-align: center;
          }

          .ng-select-container .ng-value-container {
            justify-content: center;
            color: #fff;

            .ng-placeholder {
              color: #fff;
            }
          }

          .ng-arrow-wrapper,
          .ng-clear-wrapper,
          .ng-input {
            color: #fff;
          }

          .ng-arrow-wrapper {
            position: relative;

            .ng-arrow {
              border-color: white transparent transparent;
              transition: transform 0.3s;
            }
          }

          &.ng-select-opened .ng-arrow-wrapper .ng-arrow {
            border-color: transparent transparent white;
          }
        }
      }

      .results-info {
        text-align: center;

        p {
          font-size: 1rem;
          background-color: #C2C1F4;
          color: #3632F1;
          padding: 4px;
          border-radius: 8px;
          margin: 0;
        }
      }

      @media (max-width: 480px) {
        padding: 10px;

        .search-header {
          h3 {
            font-size: 1.5rem;
          }

          p {
            font-size: 0.875rem;
          }
        }

        .search-bar {
          .search-input {
            font-size: 0.875rem;
            padding-left: 40px;
          }

          .sort-dropdown {
            .ng-select-container {
              font-size: 0.875rem;
            }
          }

          .search-icon {
            left: 10px;
            font-size: 20px;
            top: 28px;
          }
        }

        .results-info {
          p {
            font-size: 0.875rem;
          }
        }
      }
    }

    .job-search-paginator {
      padding: 10px 0px;

      .demo-toggles {
        display: flex;
        flex-direction: column;
      }

      .demo-toggles * {
        margin-bottom: 16px;
      }

      .demo-options {
        display: flex;
        flex-direction: column;
        width: 600px;
      }

      .demo-data * {
        margin: 16px 0;
      }

      .demo-paginator {
        width: 100%;
      }
    }
  }
}


// need to check these and remove after
@media (min-width: 768px) {
  .user-dashboard-wrapper {

    .profile-section {
      display: block;
    }

    .mobile-view-header {
      display: none;
    }

    .filter-section {
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .user-dashboard-wrapper {

    .profile-section {
      display: none;
      position: absolute;
      width: 100%;

    }

    .mobile-view-header {
      display: block;
    }

    .filter-section {
      display: none;
      position: absolute;
      width: 100%;
    }
  }

}