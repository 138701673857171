.create-job-postings {
  .job-postings-container {
    margin-top: auto;

    @media screen and (min-width: 990px) {
      margin-top: 60px;
    }

    .job-posting-card {
      .job-posting-header {
        display: flex;
        justify-content: space-between;
      }

      .job-posting-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .details-section {
          width: 100%;
        }

        .action-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          .delete-icon {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
        }

        .view-detailes-btn {
          height: 32px;
          width: 100%;
          align-items: center;
          justify-content: center;
          padding: 0px 10px;
          border: none;
          color: #0066ff;
          border-radius: 36px !important;
          display: flex;
          background-color: #3632F133;
        }

      }
    }
  }
}