.jobs-created-applied-container {
  filter-profiles-jobs-applied {
    .filter-profiles-jobs-applied {
      &.filter-wrapper {
        .filter-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .filter-title {
            font-weight: bold;
            font-size: 18px;
          }

          .filter-clear {
            color: #3f51b5;
            cursor: pointer;
            text-decoration: none;
            font-size: 14px;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .filter-group {
          margin-bottom: 8px;

          .group-title {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
          }

          .checkbox-group {
            display: flex;
            flex-direction: row;

            .form-check {
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              .form-check-input {
                margin-right: 10px;
              }

              .form-check-label {
                font-size: 14px;
                color: #666;
              }
            }
          }

          .range-slider {

            mat-slider {
              width: 100%;
            }

            .range-values {
              display: flex;
              justify-content: space-between;

              .range-value {
                background: #f2f2f275;
                padding: 0 10px;
                border-radius: 4px;
                border: 1px solid #f2f2f2;
                font-size: .8rem;
              }
            }
          }

          ng-select {
            width: 100%;
            margin-top: 10px;

            .ng-select-container {
              font-size: .9rem;
              border: 1px solid #ccc;
              border-radius: 4px;
              background-color: #f2f2f275;
            }
          }
        }

        .action-buttons {
          display: flex;
          justify-content: center;

          .save-btn {
            width: 100%;
            padding: 10px;
            font-size: 16px;
            font-weight: bold;
          }
        }

        @media (max-width: 600px) {
          padding: 15px;

          .filter-group {
            margin-bottom: 15px;

            .group-title {
              font-size: 14px;
              margin-bottom: 8px;
            }

            .form-check-label {
              font-size: 12px;
            }

            .range-slider {
              .range-values {
                .range-value {
                  font-size: 12px;
                }
              }
            }

            ng-select {
              .ng-select-container {
                font-size: 12px;
              }
            }
          }

          .action-buttons {
            .save-btn {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}