/* You can add global styles to this file, and also import other style files */

@import "./bootstrap/bootstrap-custom.scss";
@import "./normalize.scss";
@import "./fonts.scss";
@import "./variables.scss";
@import "./mixins.scss";
@import "./utils/util-dir";
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import './icons.scss';
@import './header.scss';
@import './footer.scss';
@import './slider.scss';
@import './login.scss';
@import './landing.scss';
@import './profile.scss';
@import "./partials/mail-shell";
@import "./partials/page-not-found";
@import "./partials/nav-bar";
@import "./partials/contact-us-detail";
@import "./partials/main-footer";
@import "./partials//dashboard";
@import "./partials/user-dashboard";
@import "./partials/create-job";
@import "./partials/profile-cards";
@import "./partials/applied-profile-card";
@import "./partials/filter-profiles-jobs-applied";
@import "./partials/personal-details";
@import "./partials/recruiter-profile";
@import "./partials/custom-tabs.component";
@import "./messages-shell.scss";
@import "./list-of-chats.scss";
@import "./chat-detail.scss";
@import "./partials/recruiter-dashboard";
@import "./partials/job-list-item";
@import "./view-job-details.component.scss";
@import "./partials/physical-attributes-form.component";
@import "./partials/photo-links.component";
@import "./partials/video-links.component";
@import "./partials/recruiter-profile-list-item.scss";
@import "./partials/drafts-created.scss";
@import "./partials/independent-profile";
@import "./partials/projects-dashboard";
@import "./partials/shortlist-bottom-popup";
@import "./partials/endorsements-list";
@import "./partials/saved-notes-dashboard";
@import "./partials/view-profile-details.component.scss";
@import "./partials/send-message-popup.scss";

body {
  font-family: $font-regular;
  font-size: 16px;
  color: $text-color;
}

* {
  font-family: Poppins, "Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
}

p {
  font-size: .9rem;
}

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  border-radius: 5px;
  padding: 0 5px;

  @media (max-width: 1200px) {
    padding: 0 10px;
  }

  //background-color: $background-color-primary;
}

a {
  &:hover {
    cursor: pointer
  }
}

.wrapper-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.wrapper-card-square {
  background-color: #fff;
  padding: 16px;
  margin: 0;
  border-radius: 0;
  border: 1px solid $color-gray-light;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.save-btn {
  &.btn {
    &:disabled {
      background-color: $grey-opacity;
      border: 1px solid $grey-opacity;
      color: $text-color;
    }

    &:hover {
      background-color: $white;
      color: $color-sky-blue-light-dark;
      border: 1px solid $color-sky-blue-light-dark;
    }

    background-color: $color-sky-blue-light-dark;
    color: $white;
    border: 1px solid $color-sky-blue-light-dark;
  }
}

a {
  color: $primary-color;
  font-size: .8rem;
}

nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.linedfont {
  @include borderfont($primary-color);
}

.sub-title {
  text-align: left;
  margin: 20px 0px;
  color: $heading-color;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.form-label {
  color: $form-color !important;
}

.error-message {
  color: $warning-color !important;
}

.highlight-message {
  color: $color-orange-light !important;
}

.height-btn {
  height: 40px;
  width: 100%;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid $primary-color;
  color: $primary-color;
  background-color: white;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  ;

  &:hover {
    box-shadow: rgb(76, 64, 237, .2) 0px 7px 29px 0px;
  }

  &.enabled {
    color: white;
    background-color: $primary-color;
  }

  img {
    position: absolute;
    right: 10px;
    bottom: 0;
    top: 10px;
  }
}

.word-break {
  word-break: break-word;
}

.form-group {

  label {
    font-size: .8rem;
    font-weight: bold;
  }
}

.form-control {
  border-radius: 8px !important;
  color: $heading-color !important;
  line-height: 2 !important;
  font-size: .9rem;

  &:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 4px $primary-color;
  }
}

.btn {
  font-size: 0.8rem;
  border-radius: 20px !important;
  min-width: 80px;
  font-weight: 500;
}

.required-star {
  color: $color-red;
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins;
}

.highlight {
  color: $heading-color;
  font-size: 1.2rem;
}

.highlight-normal {
  color: $primary-color;
  font-size: 1rem;
}

.sub-title-highlight {
  color: $accent-color;
}

.page-title,
.text-center {
  text-align: center;
}


.ant-picker {
  border: 1px solid rgba(0, 0, 0, .85);
  width: 100%;
}

.ant-picker-input>input::placeholder {
  color: gray;
}

.background-banner {
  height: 200px;
  width: 200px;
  background-image: url('/assets/images/background-banner.png');
  background-size: cover;

}

.content-wrapper {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

}

.upload-div {
  position: relative;
  height: 200px;
  width: 250px;
  display: flex;
  justify-content: center;
  flex-direction: row;

  &.nav-profile {
    height: 40px;
    width: 40px;
  }

  &.job-image {
    height: 100%;
    width: 100%;

    .label-portfolio-upload {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      img {
        height: 100%;
        width: 100%;
        position: relative;
      }

      .place-holder-img {
        height: 60%;
        width: 60%;
        object-fit: contain;
        align-self: center;
      }
    }
  }

  img,
  video {
    position: absolute;
    height: 200px;
    width: 200px;
    object-fit: cover;

    &.nav-profile {
      height: 40px;
      width: 40px;
    }
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 0px 5px;
    font-weight: 500;
  }

  .label-profile-upload {
    height: 200px;
    width: 200px;
    background-image: url('/assets/images/profile-avatar.png');
    background-size: cover;
    color: #ff7575;

    &.nav-profile {
      height: 40px;
      width: 40px;
    }

    &.no-pic {
      text-align: center;
    }
  }



  .label-portfolio-upload {
    height: 200px;
    width: 200px;

    background-size: contain;
    color: #ff7575;
    object-fit: contain;

    &.no-pic {
      text-align: center;
    }
  }

  .label-video-upload {
    height: 200px;
    width: 200px;
    background-image: url('/assets/images/video-upload.png');
    background-size: cover;
    color: #ff7575;

    &.no-pic {
      text-align: center;
    }
  }

  .label-audio-upload {
    height: 200px;
    width: 200px;
    background-image: url('/assets/images/audio-upload.png');
    background-size: cover;
    background-position: center;
    color: #ff7575;

    &.no-pic {
      text-align: center;
    }
  }

  .input-profile-upload {
    display: none;
  }

  .profile-pic {
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    border: 4px solid $grey-opacity;

    &.nav-profile {
      border: 2px solid $grey-opacity;
    }
  }

  .applied-profile-pic {
    background-size: cover;
    background-position: center;
    border: 4px solid $grey-opacity;
  }
}

.upload-div-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.application-status-container {

  display: flex;
  flex-direction: column;
  justify-content: center;

  .profile-accept,
  .applied-profile-accept {
    background: #eeeeff;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px 10px;
    display: inline-block;
    color: $color-sky-blue;
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    line-height: normal;
    display: inline-block;

  }

  .profile-reject,
  .applied-profile-reject {
    background: #eeeeff;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px 10px;
    display: inline-block;
    color: $color-red;
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    line-height: normal;
    display: inline-block;

  }
}

.delete-icon {
  color: red;
}

.upload-icon {
  color: $primary-color;
}

.job-wrapper {

  margin: 20px 0px 0px 0px;
  padding: 20px;
  border: 1px solid $grey-opacity;
  border-radius: 8px;

  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  }
}

.settings-wrapper {
  padding: 20px;
  min-height: 100%;
  height: 100%;
  background-color: white;
}

.social-media-links {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Delete Icon */
.deleteIcon {
  margin-bottom: 10px;
  color: $color-red;
  display: flex;
  flex-direction: row-reverse;

  mat-icon {
    width: auto;
    cursor: pointer;
    padding: 0;
    font-size: 32px;
    height: auto;
  }
}

/* Flex Direction Row */
@mixin dflex-row {
  flex-direction: row;
}

.job-details-card {
  margin: 20px 0px;
  padding: 20px;
  border: 1px solid $grey-opacity;
  border-radius: 8px;

  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  }

  .heading-label {
    font-weight: 600;
    font-size: 0.8rem;
  }

  .apply-btn {
    display: flex;
    justify-content: center;

    button {
      width: 50%;
    }
  }

  .border-bt {
    @media screen and (min-width: 576px) {
      display: flex;
    }

    border-bottom: 1px solid lightgray;
  }

  .job-details-image {
    background-image: url('./../images/image-placeholder.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 300px;
  }

  .job-details-description {
    padding-left: 26px;


    @media screen and (max-width: 776px) {
      padding-left: 0px;
    }

    @media screen and (min-width: 576px) {
      border-right: 1px solid $color-gray-medium;
    }

    justify-content: start;

    .fav-eye-icon {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0;
      top: 14px;
      margin-right: 14px;

      .eye-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .count {
          align-self: center;
          font-size: 0.8rem
        }
      }
    }

    .cast-details {
      p {
        margin: 0;
      }
    }

    .content-title {
      font-size: 0.8rem;
      display: block
    }

    .job-details-data {
      .job-title {
        font-size: 1.2rem;
        font-weight: 700
      }
    }
  }
}

.form-check {
  &.checkbox-fleft {
    padding-left: 1em;

    @media screen and (min-width: 576px) {
      float: left;
    }

    @media screen and (min-width: 768px) {
      padding-left: 1em;
    }

    margin-bottom: 0.25rem;

  }


}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  .tag {
    background-color: #e0e7ff;
    color: #6366f1;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 0.8rem;
    font-weight: 500;
  }
}

.click-div {
  padding: 5px 20px;
  margin: 0px 20px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:hover {
    background-color: rgb(212, 212, 212);
  }
}

.edit-option {
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  color: $primary-color;
}

.favorite-icon {
  color: red;
}

/* Padding Zero */

.pd-0 {
  padding: 0;
}

.pd-10 {
  padding: 10px;
}

.pd-26 {
  padding: 26px !important;
}

.pd-20 {
  padding: 20px;
}

.pd-40 {
  padding: 40px;
}

.pd-lt-10 {
  padding-left: 10px;
}

/* margins */

.mar-0 {
  margin: 0;
}

.mar-5 {
  margin: 5px;
}

.mar-20 {
  margin: 20px;
}

.mar-rt-5 {
  margin-right: 5px;
}

.mar-rt-10 {
  margin-right: 10px;
}

.mar-rt-20 {
  margin-right: 20px;
}

.mar-rt-46 {
  margin-right: 46px;
}

.mar-lt-5 {
  margin-left: 5px;
}

.mar-lt-10 {
  margin-left: 10px;
}

.mar-lt-32 {
  margin-left: 32px;
}

.mar-bt-20 {
  margin-bottom: 20px;
}

/* Width and Height */
.wd-100 {
  width: 100%;
}

.min-wd-300 {
  min-width: 300px;
}

.min-wd-400 {
  min-width: 400px;
}

.min-ht-200 {
  min-height: 200px;
}

.width-auto-radio {
  width: auto;
  margin-right: 30px;
}

.chip-container {
  height: 40px;
  min-width: 40px;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 0px 10px;
  border: 2px solid #0066ff;
  color: black;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.heading-label-bold {
  font-weight: 600;
  font-size: 0.8rem;
}

/* Align text */
.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-link {
  color: $primary-color;
}

.cursor-pointer {
  cursor: pointer;
}


/* Fonts Sizes */
.font-size-20 {
  font-size: 20px;
}

.font-size-18 {
  font-size: 18px !important;
}

/* font-weights */

.font-wt-500 {
  font-weight: 500 !important;
}

.font-wt-600 {
  font-weight: 600 !important;
}

/* dflexs */
.dflex-column {
  display: flex;
  flex-direction: column;
}

.dflex-row {
  display: flex;
  flex-direction: row;
}

.dflex {
  display: flex;
}

/* Justify Contents */
.justify-space-bt {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-flex-end {
  justify-content: flex-end;
}

.spin-loader {
  color: $white;
}

.apply-sigin-view-btn {
  background-color: $color-sky-blue-light-dark;
  color: $white;
  border-radius: 34px !important;
  padding: 6px 20px;
  font-weight: 500;

  &:hover {
    background-color: $white;
    color: $color-sky-blue-light-dark;
    border: 1px solid $color-sky-blue-light-dark;
  }

  &.btn {
    &:disabled {
      background-color: $grey-opacity;
      border: 1px solid $grey-opacity;
      color: $text-color;
    }
  }
}

.danger-btn {
  background-color: $color-red;
  color: $white;
  border: 1px solid $color-red;
  border-radius: 34px !important;

  &:hover {
    background-color: $white;
    color: $color-red;
    border: 1px solid $color-red;
  }
}

.asterisk {
  color: $color-red;
}