.view-job-details-card {
  margin: 16px auto !important;
  padding: 32px !important;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;

  .company-info {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;

    .view-count {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 10px;
      font-size: 14px;
    }

    .company-logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 12px;
    }

    h1 {
      margin: 0;
      font-size: 24px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h2 {
      font-size: 32px;
      margin: 0;
    }

    p {
      margin: 0;
      color: #666;
    }

    .bookmark-apply-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mat-icon {
        font-size: 34px;
        color: red;
        height: 38px;
        width: 34px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
      }

      button {
        padding: 8px 16px;
      }

      .applied-btn {
        width: 100%;
        background: #eeeeff;
        border-radius: 5px;
        font-size: 14px;
        padding: 5px 10px;
        display: inline-block;
        color: #0066ff;
        transition: ease all 0.5s;
        -webkit-transition: ease all 0.5s;
        -ms-transition: ease all 0.5s;
        line-height: normal;
        margin-bottom: 12px;
        text-align: center;
      }
    }
  }

  .job-info {
    .section {
      margin-bottom: 24px;

      .info-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .info-row p {
        display: flex;
        width: 100%;
      }

      .info-row strong {
        flex: 0 0 35%;
      }

      h3 {
        font-size: 24px;
      }

      // Need to check and remove if not using below class
      .details-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;

        div {
          strong {
            display: block;
            font-weight: 600;
          }

          p {
            margin: 4px 0 0 0;
            color: #444;
          }
        }
      }
    }

    .images-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;

      img {
        width: 100%;
        height: 280px;
        border-radius: 4px;
      }
    }
  }

  .form-group {
    margin-bottom: 15px;

    label {
      font-weight: 600;
    }
  }
}

.filter-jobdetails {
  margin: 0 !important;
  border-radius: 0 !important;
  border: 1px solid $color-gray-light;
}

@media (max-width: 768px) {
  .view-job-details-card {
    padding: 16px;
    margin: 12px;

    .company-info {
      flex-direction: column;
      align-items: flex-start;

      .company-logo {
        margin-right: 0;
        margin-bottom: 8px;
      }

      h1 {
        font-size: 20px;
      }
    }

    .header {
      flex-direction: column;
      align-items: flex-start;

      h2 {
        font-size: 24px;
      }

      .bookmark-apply-btn {
        width: 100%;
        margin-top: 16px;
        justify-content: space-between;

        .mat-icon {
          font-size: 28px;
          height: 32px;
          width: 28px;
        }

        button {
          width: 100%;
          padding: 8px;
        }
      }
    }

    .job-info {
      .section {
        margin-bottom: 16px;

        .info-row {
          flex-direction: column;
          align-items: flex-start;

          p {
            margin: 4px 0;
          }

          strong {
            flex: 0 0 auto;
            margin-bottom: 4px;
          }
        }

        h3 {
          font-size: 20px;
        }

        .details-grid {
          grid-template-columns: 1fr;
        }
      }

      .images-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media (max-width: 480px) {
  .view-job-details-card {
    padding: 12px;
    margin: 12px;

    .company-info {
      h1 {
        font-size: 18px;
      }
    }

    .header {
      h2 {
        font-size: 20px;
      }

      .bookmark-apply-btn {
        flex-direction: column;
        align-items: flex-start;

        .mat-icon {
          font-size: 24px;
          height: 28px;
          width: 24px;
          margin-bottom: 8px;
        }

        .apply-job-btn {
          width: 100%;

          button {
            width: 100%;
            padding: 8px;
          }
        }
      }
    }

    .job-info {
      .section {
        .info-row {
          p {
            margin: 2px 0;
          }

          strong {
            margin-bottom: 2px;
          }
        }

        h3 {
          font-size: 18px;
        }

        .details-grid {
          grid-template-columns: 1fr;
        }
      }

      .images-grid {
        grid-template-columns: 1fr;
      }
    }
  }
}