.recruiter-profile-wrapper-edit-mode {

  // .upload-div {
  //   justify-content: flex-start;
  // }
  .recruiter-bg-profile-image {
    background-color: #e5e5f7;
    background-image: radial-gradient(#999df6 0.65px, transparent 0.65px), radial-gradient(#999df6 0.65px, #e5e5f7 0.65px);
    background-size: 26px 26px;
    background-position: 0 0, 13px 13px;
    padding: 10px;
    margin: 10px 0px;

    .upload-div {
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;

      .action-btns-div {
        z-index: 9999;
      }
    }
  }

  .production-capabilities-expertise {
    .previous-works {
      .previous-experience-wrapper {
        border-top: 1px solid $grey-opacity;
        padding-top: 30px;
        margin-top: 30px;

        &:first-child {
          border: 0;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

.recruiter-profile-wrapper-display-mode {
  .edit-mode-icon {
    display: flex;
    justify-content: flex-end;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .experience-divider {
    border-bottom: 1px solid $grey-opacity;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .heading-wrapper {
    margin-bottom: 20px;
  }

  .recruiter-profile-data-section {
    // border-bottom: 1px solid lightgray;
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .profile-image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}