@import "./text";
@import "./animations";

.color-primary {
  color: $primary-color;
}

.cursor {
  cursor: pointer;
}


.form-check-input {
  border-radius: 4px;
  outline: none;
}