.nav-bar-wrapper {


  width: 100%;
  z-index: 999;
  padding: 10px 50px;

  // rgba(94, 94, 94, 0.15)
  &.navbar-shadow {
    box-shadow: $hover-color 0px 1px 4px;
  }

  .nav-bar-info {
    .navbar-brand {
      font-size: 2rem;
    }
  }

  .nav-bar-items-wrapper {
    display: flex;
    flex-direction: row;
  }

  .nav-item {
    margin: 0px 20px;

    .nav-link {
      color: $text-color;
      font-size: .8rem;
      font-weight: 500;

      &.active {
        color: $primary-color !important;
      }
    }

    .icon {
      &.active {
        color: $primary-color !important;
      }
    }
  }

  .material-icons-outlined {
    &.act-icon {
      font-size: 3rem;
      height: 3rem;
      width: 3rem;
    }
  }

  .display-flex-center {
    display: flex;
    align-items: center;
  }
}




@media (max-width: 994px) {
  .nav-bar-wrapper {
    padding: 0px 10px 0px 10px;

    .nav-bar-info {
      .navbar-brand {
        font-size: 1.6rem;
      }
    }

    .nav-bar-items-wrapper {
      display: none;

      &.open {
        background-color: $background-color-primary;
        display: block;
        position: fixed;
        top: 56px;
        left: 0;
        height: 100%;
        width: 100%;

        ul {
          padding: 1.5rem;
        }
      }
    }
  }
}


.nav-bar-wrapper {
  .notification-side-bar {
    .sidebar-container {
      position: fixed;
      top: 0;
      right: -300px;
      height: 100%;
      width: 300px;
      max-width: 80%;
      background: #ffffff;
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
      transition: right 0.3s ease-in-out;
      z-index: 1000;

      &.active {
        right: 0;
      }

      .sidebar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        background-color: #f5f5f5;
        border-bottom: 1px solid #ddd;

        h3 {
          margin: 0;
        }
      }

      .sidebar-content {
        padding: 1rem;
        overflow-y: auto;
        height: calc(100% - 50px);
      }
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }
  }
}