.dashboard-wrapper {
  min-height: 600px;
  background-color: $background-color-primary;
  z-index: 0;
  position: relative;

  .shape1 {
    position: absolute;
    top: -35%;
    left: -20%;
    z-index: -1;
  }

  .shape2 {
    position: absolute;
    left: 0%;
    top: -35%;
    z-index: -1;

    path {
      stroke: $text-color !important;
    }
  }

  .content-wrapper {
    padding: 100px 0px;

    .text-wrapper {
      padding-top: 200px;

      .sub-title {
        font-size: 16px;
        line-height: 30px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $text-color;
      }

      .title {
        font-size: 70px;
        line-height: 78px;
        font-weight: 600;
        color: $text-color;
        margin-top: 10px;
      }

      .pre-title-text {
        font-size: 24px;
        line-height: 36px;
        font-weight: 500;
        color: $text-color;
        margin-top: 30px;
      }
    }
  }

  .services-wrapper {
    background-image: linear-gradient(to bottom right, #7215e8, #9825ce);
    background-color: #7215e8;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-bottom: 110px;

    .wrapper {
      padding: 50px 0px;


    }
  }

  .about-wrapper {
    padding: 50px 0px;
  }
}

@media (max-width: 994px) {
  .shape1 {
    display: none;
  }

  .shape2 {
    display: none;
  }

  .services-wrapper {
    .wrapper {
      .row {}
    }
  }
}