$color-sky-blue: #0066ff;
$color-sky-blue-light-dark: #0066ff;
$color-blue-light-violet: #ebeaff;
$color-grayish-blue: #7c9fc4;
$color-sky-blue-light: #a1c4ec;
$color-sky-blue-dark: #2e74ab;
$color-violet-light: #b3a1e8;
$color-blue-dark: #157cd9;
$color-green-dark: #025148;
$color-green-light: #266a2f;
$color-green-medium: #0b4e19;
$color-green-light-parrot: #47B165;
$color-orange: #ff971d;
$color-gray: #757575;
$color-gray-dark: #4d4d4d;
$color-gray-medium: #dedede;
$color-gray-medium-dark: #9e9e9e;
$color-gray-light: #e5e5e5;
$color-white: rgb(248, 249, 250);
$white : #ffffff;
$color-red: #ff4444;
$color-black-dark: #28283C;
$color-black-darker: #202020;
$color-brown-light: #e18b43;
$color-violet-dark: #7215e8;
$color-violet-medium: #9825ce;
$color-orange-dark: #fb8500;
$color-orange-light: #ffb703;
$color-blue-light-gray: #6e697f;
$color-gray-light-gray: #e1e1e1;
$color-gray-light-darker: #444;
$color-gray-light-lighter: #F3F3F5;

$grey: #cccccc;
$grey-opacity: rgba(204, 204, 204, 0.5);
$color-gray-dark: #333333 // color palatte
  //https://in.pinterest.com/pin/62628251057781263/