// .main {
//     height: calc(100vh - 112px);
//     display: flex;
//     padding-top: 100px;
// }
account-shell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
}

otp-validation,
email-verification,
feedback,
password-update,
sign-up,
recruiter-contact-us,
login {
  width: 100%;

  .form-group {
    margin-bottom: 15px;

    label {
      font-size: .8rem;
      font-weight: bold;
    }
  }

  .logo {
    a.navbar-brand {
      font-size: 2rem;
      color: $text-color !important;
      font-weight: 500;

      span {
        color: $primary-color;
      }
    }
  }

  button {
    width: 100%;
  }

  .forgot-links {
    display: flex;
    justify-content: flex-end;
  }
}


/* Signup Login Forgot Screen Styles */

.signup-container,
.login-container,
.reset-pswd-container,
.pswd-update-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px;
  align-items: stretch;

  .signup-form,
  .login-form,
  .reset-pswd-form,
  .pswd-update-form {
    flex: 0 0 48%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form {
      min-width: 85%;
      max-width: 100%;

      @media screen and (min-width: 768px) {
        min-width: 60%;
        max-width: 80%;
      }

      h2 {
        padding-bottom: 40px !important;
        font-weight: 600 !important;
      }
    }

    .text-link {
      text-decoration: none;
    }

    .input-group {
      position: relative;
    }

    .input-group-append {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0px 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 999;
    }

  }

  .signup-log,
  .login-logo,
  .reset-pswd-logo,
  .pswd-update-logo {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      border-radius: 12px;
      object-fit: cover;
      width: 100%;
      max-width: 640px;
      height: 100%;
    }
  }

  .signup-log {
    flex: 1;
  }

  .signup-form {
    flex: 2;

    @media screen and (max-width: 768px) {
      padding: inherit;
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}


// Signup register options
.signup-container,
.login-container {
  .register-type-options {
    display: flex;
    flex-direction: row;

    .info-icon {
      padding: 2px 5px 5px;

      mat-icon {
        font-size: 14px;
        color: $color-sky-blue;
      }
    }
  }
}


// Signup form
.signup-container {
  .signup-form {
    .account-type-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-option,
      .right-options {
        flex: auto;

        @media screen and (min-width: 480px) {
          flex: 1;
        }
      }
    }

    .signup-desc {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}