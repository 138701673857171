footer {
  background: $text-color;
  padding: 10px 0;
  color: $white;

}

.footer-container {
  .footer-title {
    width: 100%;

    font-size: 1.4rem;
    font-weight: 500;
  }

  .line-item {
    line-height: 30px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}