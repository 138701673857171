profile-shell {
  display: block;

  .profile-progress {
    margin-bottom: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: -20px;
    z-index: 1;
  }

  .profile-banner-section {
    background: #ff4848;
    padding: 10px;
    text-align: center;
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
  }
}

personal-details-form,
physical-attributes-form,
work-experience-form,
education-details-form,
uniquie-talent-ability-form,
special-attributes-form {

  .form-group {
    margin-bottom: 15px;
  }
}

skills-form,
special-attributes-form,
uniquie-talent-ability-form,
interests-form {
  ng-select {
    ng-dropdown-panel {
      width: auto !important;
    }
  }
}

.quick-links-section {
  .info-label {
    p {
      display: flex;
      width: 100%;

      span:first-child {
        flex: 0 0 75%;
      }

      a {
        text-decoration: none;
      }
    }
  }
}

.progress {
  position: relative;
}

.percent {
  position: absolute;
  width: 0;
  height: 100%;
  background: $primary-color;
  transition: width 1s;
}

.display-mode {
  label {
    font-weight: 600;
    font-size: .8rem;
  }
}

.common-bg {
  background: $white;
  padding: 20px;
  border: 1px solid $grey-opacity;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 20px 0px 0px 0px;

  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid $grey-opacity;

    .btn {
      min-width: 100px;
    }

    &.align-right {
      justify-self: right;
    }
  }
}

.dob {
  .mat-mdc-form-field {
    width: 100%;
  }

  .mdc-text-field {
    padding: .375rem .75rem;
  }

  .mat-mdc-text-field-wrapper {
    border: 1px solid #ced4da;
    background: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border: 0;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    min-height: auto;
    padding: 0;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    height: auto;
    padding: 0;
  }

  .mdc-icon-button {
    font-size: 0;
  }

  .mat-mdc-icon-button {
    --mat-mdc-button-persistent-ripple-color: none;
    --mat-mdc-button-ripple-color: none;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.dflex {
  display: flex;
  justify-content: space-between;
}

.heading-wrapper {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.experience-wrapper {
  border-top: 1px solid $grey-opacity;
  padding-top: 30px;
  margin-top: 30px;

  &:first-child {
    border: 0;
    padding: 0;
    margin: 0;
  }
}

/* Profile Search */
.profile-search {
  .chips-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-chip {
      height: 34px;
      border-radius: 17px;
      background-color: $accent-color;
      color: $white;
      border: 1px solid $accent-color;
      padding: 0px 10px;

      &.active {
        background-color: $white ;
        color: $accent-color;
      }
    }
  }

  .filter-section {
    padding: 0 40px;
  }

  .profile-details-wrapper {
    .profile-pic {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
      max-width: 200px;

      img {
        height: 200px;
        width: auto;
        object-fit: cover;
      }
    }

    .profile-details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .profile-data {
      text-align: center;
      flex: 1 1 auto;

      .mar-bot-6 {
        margin-bottom: 6px;
        font-size: 1.2rem;
      }
    }

    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      .btn-primary {
        height: 100%;
        max-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media (max-width: 900px) {
      .row {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .button-container {
        width: 100%;
        margin-top: 10px;

        .btn-primary {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .profile-search {
    .mobile-view-header {
      display: none;
    }

    .filter-section {
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .profile-search {
    .mobile-view-header {
      display: block;
      margin: 12px;
    }

    .filter-section {
      display: none;
      position: absolute;
      width: 100%;
    }
  }
}

.display-mode-bubble-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .display-mode-bubble-content {
    min-height: 40px;
    min-width: 40px;
    align-items: center;
    justify-content: center;
    margin: 5px;
    padding: 0px 10px;
    border: 2px solid #0066ff;
    color: #0066ff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;

  }
}

.jobs-created-container {
  .job-cards {
    border-radius: 15px;
  }

  //need to check and remove below
  // .show-profile-content {
  //   display: flex;
  //   flex-direction: row;

  //   .applied-count {
  //     margin-left: 10px;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //   }
  // }
}


recruiter-shell {
  profiles-search {
    .profile-logo {
      width: 88px;
      height: 88px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}


// Filters in profile and job search common styles
.profile-job-common-filter {
  &.filter-wrapper {
    .filter-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .filter-title {
        font-weight: bold;
        font-size: 18px;
      }

      .filter-clear {
        color: #0066ff;
        cursor: pointer;
        text-decoration: none;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .filter-group {
      margin-bottom: 20px;

      .group-title {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;

      }

      .advanced-filter {
        font-size: 16px;
        margin-bottom: 10px;
        color: #0066ff;
        text-align: center;
        cursor: pointer;
      }

      .checkbox-group {
        display: flex;
        flex-direction: column;

        .form-check {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .form-check-input {
            margin-right: 10px;
          }

          .form-check-label {
            font-size: 14px;
            color: #666;
          }
        }
      }

      .range-slider {

        mat-slider {
          width: 100%;
          margin-bottom: 10px;
        }

        .range-values {
          display: flex;
          justify-content: space-between;

          .range-value {
            background: #f2f2f275;
            padding: 5px 10px;
            border-radius: 4px;
            border: 1px solid #f2f2f2;
            font-size: .8rem;
          }
        }
      }

      ng-select {
        width: 100%;
        margin-top: 10px;

        .ng-select-container {
          font-size: .9rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: #f2f2f275;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: center;

      .save-btn {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    @media (max-width: 600px) {
      padding: 15px;

      .filter-group {
        margin-bottom: 15px;

        .group-title {
          font-size: 14px;
          margin-bottom: 8px;
        }

        .form-check-label {
          font-size: 12px;
        }

        .range-slider {
          .range-values {
            .range-value {
              font-size: 12px;
            }
          }
        }

        ng-select {
          .ng-select-container {
            font-size: 12px;
          }
        }
      }

      .action-buttons {
        .save-btn {
          font-size: 14px;
        }
      }
    }
  }
}