.create-job-wrapper {
  .common-bg {
    margin: 0px;

    &.image-upload-div {
      box-shadow: none;
      border-radius: 0px;
    }

    .skill-options {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .media-box {
      display: flex;
      flex-direction: row;
    }

    .audition-content {
      position: relative;
      max-width: 300px;
      width: 100%;
      height: 36px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      display: inline-flex;

      .audition-input {
        width: 100%;
        height: 100%;
        border: 0;
        font-size: 16px;
      }
    }

    .btn-primary {
      &.save-btn {
        width: 100%;
      }
    }
  }

  .range-slider {

    mat-slider {
      width: 100%;
      margin-bottom: 10px;
    }

    .range-values {
      display: flex;
      justify-content: space-between;

      .range-value {
        background: #f2f2f275;
        padding: 5px 10px;
        border-radius: 4px;
        border: 1px solid #f2f2f2;
        font-size: .8rem;
      }
    }
  }

  .demo-inline-calendar-card {
    width: 300px;
  }

  .min-ht-200 {
    min-height: 200px;
  }

  .max-wd-300 {
    max-width: 300px;
  }

  .create-job-postings {
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    .create-show-case-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        flex-direction: row;
      }

      .heading-btns {
        display: flex;
        align-items: center;

        .save-btn {
          background-color: $color-sky-blue-light-dark;
          color: $white;
          padding: 6px 20px;
          font-weight: 500;
        }
      }
    }
  }
}