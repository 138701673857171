.endorsements-list-wrapper {
  margin-top: 20px;

  .container {
    padding: 2rem 0rem;
  }

  h4 {
    margin: 2rem 0rem 1rem;
  }

  .table-image {

    td,
    th {
      vertical-align: middle;
    }
  }

  th.sortable {
    position: relative;
    padding-right: 30px;
    cursor: pointer;
  }

  .sort-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust the positioning of the icon */
    transform: translateY(-50%);
    font-size: 14px;
    /* Adjust icon size */
    width: 20px;
    /* Fixed width for the icon container */
    text-align: center;
  }

  table th,
  table td {
    white-space: nowrap;
    /* Prevent text wrapping */
  }

  table tbody {
    .profile-image-thumbnail {
      height: 60px;
      width: 60px;
    }

    .actions-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .action-icon {
        height: 40px;
        width: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-right: 10px;


        &.edit {
          color: $primary-color;
          font-size: .9rem;
        }

        &.delete {
          color: #ff4444;
          font-size: .9rem;
        }

        mat-icon {
          color: white;
        }
      }
    }
  }
}

// Request Endrosements Styles
.add-endorsements-wrapper {
  .add-endorsements {
    .indsutry-profile-selected {
      height: 80px;
      padding: 20px;
      background-color: rgb(248, 249, 250);
      border: 1px solid #ced4da;
      border-radius: 8px;
    }

    .notes-selected {
      height: 130px;
      padding: 20px;
      background-color: rgb(248, 249, 250);
      border: 1px solid #ced4da;
      border-radius: 8px;
    }

    .request-endorsement-submit {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}