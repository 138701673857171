// .video-btn-link {
//      display: flex;
//      align-items: center;
//      flex-direction: row;
//      justify-content: space-between;

//      button {
//           font-size: 12px;
//      }
// }

.embed-responsive-item {
     width: 100%;
     height: 270px;
     border-radius: 12px;
}