.saved-notes-dashboard {
     &.container-fluid {
          &.notes-container {
               height: 100%;
               width: 100%;
          }

          .row {
               height: 100%;

               @media (max-width: 768px) {
                    height: 968px;
               }
          }

          .notes-list-left {
               padding: 0;

               @media (max-width: 768px) {
                    margin-bottom: 10px;
               }
          }

          .notes-open-right {
               padding-left: 0;

               .notes {
                    height: 100%;

                    .notes-header {
                         background-color: #f5f5f5;
                         display: flex;
                         align-items: center;
                         justify-content: space-between;
                         padding: 15px;
                         border-bottom: 1px solid $color-gray-light;
                         height: 80px;

                         h3 {
                              margin: 0;
                              font-size: 1.5rem;
                              flex: 1; // Allows the title to take available space
                              text-align: left; // Align text to the left
                         }

                         .profile-send-message {
                              display: flex;
                              gap: 10px;

                              .save-btn {
                                   min-width: 120px;
                              }
                         }
                    }

                    .notes-messages-background {
                         display: flex;
                         flex-direction: column;
                         flex-grow: 1;
                         padding: 15px;
                         background-color: white;

                         .notes-content {
                              flex-grow: 1; // Ensures textarea takes up available space
                              display: flex;

                              textarea {
                                   flex-grow: 1;
                                   width: 100%;
                                   padding: 10px;
                                   background: #d3d3d3;
                                   font-size: 16px;
                                   border-radius: 5px;
                                   outline: none;
                                   height: 64vh;
                                   display: block;
                                   text-align: start;
                              }
                         }

                         .notes-btns {
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                              padding: 10px;
                              background: white;
                              border-top: 1px solid #ccc;

                              .cancel {
                                   flex: 1;
                                   text-align: left;
                              }

                              .clear-save {
                                   display: flex;
                                   gap: 15px;
                              }
                         }
                    }
               }

               .wrapper {
                    &.wrapper-text {
                         text-align: center;
                         font-size: 1.5rem;
                         height: 100%;
                         width: 100%;
                         justify-content: center;
                         display: flex;

                         .row {
                              display: flex;
                              justify-content: center;
                              align-items: center;
                         }

                         text-align: center;
                         font-size: 1.5rem;
                    }
               }
          }

          .avatar {
               width: 40px;
               height: 40px;
               border-radius: 50%;
               margin-right: 10px;
          }

          .chat-list-header {
               display: flex;
               justify-content: space-between;
               align-items: center;
               padding: 10px;

               .chats-title {
                    font-size: 18px;
                    font-weight: bold;
               }

               .icons-container {
                    display: flex;
               }

               //  chat icons edit and more styles
               .icons-container span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    /* Grey background color */
                    margin-left: 10px;
                    /* Adjust spacing between icons */
               }

               .icons-container span mat-icon {
                    color: $color-white;
                    /* White icon color */
               }
          }

          .search-bar {
               width: 100%;
               height: 80px;
               position: relative;
               display: inline-block;
               padding: 16px 24px;
               border: 1px solid $color-gray-light;

               input[type="text"] {
                    width: 100%;
                    padding-left: 58px;
                    border: 1px solid #aaa;
                    border-radius: 30px;
                    background-color: #E9E9E9;
                    outline: none;
                    height: 52px;
                    box-sizing: border-box; // Include padding and border in width calculation
               }

               mat-icon {
                    position: absolute;
                    top: 56%;
                    left: 42px;
                    transform: translateY(-50%);
                    cursor: pointer;
               }

               input::placeholder {
                    color: #888;
               }
          }

          //  Message Inbox styles
          .inbox-text-container {
               .inbox-text {
                    color: $color-white;
                    padding: 8px 15px;
                    border-radius: 20px;
               }
          }

          .profiles-list {
               overflow-y: auto;
               height: calc(100% - 80px);
               border: 1px solid $color-gray-light;
               max-height: 555px;

               .profile-item {
                    display: flex;
                    align-items: center;
                    padding: 20px 32px;
                    cursor: pointer;
                    border-bottom: 1px solid $color-gray-light;
                    transition: background-color 0.3s ease;

                    &:hover {
                         background-color: rgba(0, 0, 0, 0.05);
                    }

                    &.selected {
                         background-color: rgba(0, 0, 0, 0.2);
                    }

                    .profile-picture {
                         width: 50px;
                         height: 50px;
                         border-radius: 50%;
                         margin-right: 15px;
                    }

                    .profile-details {
                         flex-grow: 1;
                         display: flex;
                         flex-direction: column;

                         .profile-name {
                              font-size: 16px;
                              font-weight: 600;
                              color: $color-gray-dark;
                         }

                         .message-value {
                              color: $color-gray-medium-dark;
                              font-size: 14px;
                         }
                    }

                    .chat-meta {
                         text-align: right;
                         flex-shrink: 0;

                         .chat-time {
                              font-size: 12px;
                              color: $color-gray-medium-dark;
                              display: block;
                         }

                         .unread-count {
                              color: #f57c00;
                              font-size: 12px;
                              margin-top: 5px;
                              display: inline-block;
                              text-align: center;
                         }
                    }
               }
          }
     }
}