// .job-card {
//   background-color: #ffffff;
//   border-radius: 15px;
//   box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
//   padding: 20px;
//   margin-bottom: 20px;
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   position: relative;

//   .job-card-header {
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     padding: 12px;
//     position: relative;

//     .company-logo {
//       width: 60px;
//       height: 60px;
//       object-fit: cover;
//     }

//     .job-card-title {
//       flex: 1;

//       h4 {
//         margin: 0;
//         font-size: 1.2rem;
//         font-weight: 600;
//       }

//       .filter-title-heading {
//         max-width: 232px;
//       }

//     }

//     .job-list-icons {
//       display: flex;
//       align-items: center;
//       gap: 10px;
//       position: absolute;
//       top: 0;
//       right: 0;
//       padding: 10px;

//       .cursor-pointer {
//         display: flex;
//         align-items: center;
//       }

//       .visible-icon {
//         display: flex;
//         align-items: center;

//         .mat-icon {
//           margin-right: 5px;
//         }

//         .views-count {
//           font-size: 0.8rem;
//           color: #6b7280;
//         }
//       }
//     }
//   }

//   .job-card-footer {
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-start;
//     padding: 10px 0;

//     .info {
//       display: flex;
//       align-items: center;
//       font-size: 0.8rem;
//       color: #6b7280;
//       position: relative;
//       bottom: -24px;
//       left: 14px;

//       .info-item {
//         display: flex;
//         align-items: center;

//         mat-icon {
//           font-size: 1rem;
//           color: #6b7280;
//           display: flex;
//           align-items: center;
//         }

//         &.created-jobs {
//           color: blue;
//           cursor: pointer;
//         }
//       }

//       .separator {
//         width: 1px;
//         height: 20px;
//         background-color: #6b7280;
//         margin: 0 10px;
//       }
//     }
//   }
// }

// // Removing spaces based on filter pages
// .filter-joblist {
//   margin-bottom: 0;
//   border-radius: 0;
//   border: 1px solid $color-gray-light;
//   padding: 16px 0;
//   cursor: pointer;
// }

// .filter-joblist-selected {
//   background-color: #dde1e7;
// }

// @media (max-width: 600px) {
//   .job-card {
//     padding: 15px;

//     .job-card-header {
//       flex-direction: column;
//       align-items: flex-start;

//       .company-logo {
//         width: 40px;
//         height: 40px;
//         object-fit: cover;
//       }

//       .job-card-title {
//         h4 {
//           font-size: 1rem;
//         }

//         .tags {
//           flex-wrap: wrap;
//           gap: 3px;

//           .tag {
//             padding: 3px 7px;
//             font-size: 0.7rem;
//           }
//         }
//       }

//       .job-list-icons {
//         justify-content: flex-end;
//         width: 100%;

//         .visible-icon .views-count {
//           font-size: 0.8rem;
//         }
//       }
//     }

//     .job-card-footer {
//       flex-direction: column;
//       gap: 40px;
//       align-items: inherit;

//       .info {
//         flex-wrap: wrap;
//         gap: 5px;
//       }
//     }
//   }
// }


// NEW SCSS

// Removing spaces based on filter pages
.filter-joblist {
  margin-bottom: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;

  .profile-card {
    border-radius: 0px !important;
    margin: 4px 0px 0px 0px !important;

  }
}

.filter-joblist-selected {
  background-color: rgb(236, 236, 255) !important;
}

.filter-joblist-applied-views {
  display: flex;
  gap: 22px;
  align-items: baseline;
}


.job-card-container {
  &.profile-card-wrapper {
    .profile-card {
      display: flex;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      margin: 0 auto 20px;
      transition: all 0.3s ease;

      .profile-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (min-width: 768px) {
          flex-direction: row;
        }
      }

      .profile-image {
        flex: 0 0 auto;
        height: auto;
        display: block;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0;
        }

        @media (min-width: 768px) {
          width: 220px;
          height: 232px;
        }
      }

      .profile-info {
        flex: 1;
        padding: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .profile-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .profile-role {
            color: $color-green-light-parrot;
            font-weight: 500;
          }

          .profile-actions {
            display: flex;
            gap: 10px;
          }
        }

        .profile-name {
          font-weight: 600;
          margin: 8px 0;
          font-size: 1rem;

          @media (min-width: 768px) {
            font-size: 1rem;
          }
        }

        .details-and-button {
          margin-bottom: 10px;
          font-size: .8rem;
        }

        .profile-card-btns {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          justify-content: flex-end;

          .btn {
            &.save-btn {
              padding: 6px 16px;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }

        .profile-footer {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: baseline;

          .person-views {
            display: flex;
            flex-direction: column;
          }

          @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;

            .person-views {
              flex-direction: row;
              align-items: center;
            }

            .separator {
              width: 1px;
              height: 20px;
              margin: 0 10px;
              background-color: #6b7280;
            }
          }

          .created-jobs {
            color: blue;
            cursor: pointer;
          }

          .profile-location,
          .profile-skills,
          .info-item,
          .created-jobs {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: flex-start;
            font-size: .8rem;

            mat-icon {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}

.view-jobs-applied {
  .profile-content {
    flex-direction: column !important;
  }

  .profile-image {
    width: auto !important;
    height: 320px !important;
  }

  .profile-info {
    .profile-name {
      margin: 18px 0 !important;
    }

    .profile-card-btns {
      justify-content: flex-start !important;
    }
  }

}