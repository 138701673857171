.slider {
  position: relative;

  .slide1 {
    // background: url('../images/slider1.jpg') no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-attachment: fixed;
  }

  .slide-content {


    @media screen and (min-width: 768px) {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 300px;
      max-width: 1280px;
      padding: 0 15px;
    }

    h3 {
      font-family: $font-semibold;
      color: $white;

      span {
        display: block;
        @include borderfont($white);
      }

      @media screen and (min-width: 768px) {
        font-size: 3rem;
      }
    }

    p {
      color: $white;
      font-size: 1.5rem;

      @media screen and (min-width: 768px) {
        font-family: $font-thin;
      }
    }

    .carrer-values {
      display: flex;

      .values {
        margin-right: 40px;
      }

      p {
        font-size: 0.8rem;

        &:first-child {
          font-size: 2rem;
          font-family: $font-semibold;
          margin: 0;
        }
      }
    }
  }
}

.owl-theme .owl-nav {
  position: absolute;
  right: 40px;
  bottom: 50px;

  .owl-next {
    position: relative;

    &::before {
      content: '\eb0d';
      font-family: 'lineicons';
    }
  }

  .owl-prev {
    position: relative;

    &::before {
      content: '\eb0f';
      font-family: 'lineicons';
    }
  }
}

.steps {
  background: $white;
  padding: 20px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  z-index: 99;

  .wrapper {
    // display: flex;
    // justify-content: space-between;

    .step {
      position: relative;
      padding-left: 25px;

      p {
        color: $text-color;
        margin: 0;
        font-size: 0.7rem;

        &:first-child {
          color: $primary-color;
          display: flex;
          align-items: center;
          font-size: 1rem;

          .lni {
            margin-right: 10px;
            position: absolute;
            left: 0;
          }
        }
      }
    }

    .border {
      background: $background-color-secondary ;
      width: 1px;
    }
  }
}

.aboutus {
  padding: 5rem 0 calc(5rem - 30px);


  .wrapper {
    .row {
      display: flex;
      align-items: center;

      .col-sm-7 {
        background: $white;
        padding: 40px;
        border: 1px solid $grey-opacity;

        p {
          &:first-child {
            font-family: $font-bold;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  .about-wrapper {
    position: relative;
    text-align: center;
    margin-bottom: 50px;

    h3 {
      white-space: wrap;
      font-size: 2rem;
      font-family: $font-bold;
      @include borderfont($text-color);
      line-height: normal;
    }

    .about-content {
      // position: absolute;
      // top: 0;

      p {
        font-size: 1.5rem;
        font-family: $font-bold;

        span {
          color: $primary-color;
        }
      }
    }
  }
}