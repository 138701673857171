header {
  background: rgba(255, 255, 255, 1);
  border-bottom: 2px solid #ccc;

  @media screen and (min-width: 768px) {
    border: 0;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .logo {
      a.navbar-brand {
        font-size: 2rem;
        color: $text-color !important;
        font-weight: 500;

        span {
          color: $primary-color;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .app-logo {
            width: 60px;
          }
        }
      }
    }

    .menu {
      display: flex;

      .navbar-toggle {
        align-items: center;
        display: flex;
        text-decoration: none;
        color: $text-color;

        span {
          font-size: 20px;
        }

        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      .menu-items {
        margin: 0;
        position: fixed;
        top: 69px;
        bottom: 0;
        left: -220px;
        background: rgba(255, 255, 255, 1);
        width: 220px;
        transition: all 300ms;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

        @media screen and (min-width: 768px) {
          position: relative;
          top: auto;
          left: auto;
          bottom: auto;
          width: auto;
          background: none;
          box-shadow: none;
        }

        &.open {
          left: 0;
        }

        ul {
          display: flex;
          margin: 0;
          flex-direction: column;

          @media screen and (min-width: 768px) {
            flex-direction: row;
          }

          li {
            margin: 0;
            padding: 15px 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
            align-items: center;

            &.nav-item {
              &.login-singnup-btns {
                background-color: $color-sky-blue-light-dark;
                color: $white;
                border-radius: 34px !important;
                padding: 6px 20px;
                font-weight: 500;

                a {
                  color: $white;
                }
              }
            }

            &.signup {
              background: $primary-color;

              a {
                color: $text-color;
              }
            }

            @media screen and (min-width: 768px) {
              margin: 0 10px;
              padding: 5px 10px;
              border-bottom: 0;

              &.signup {
                border-radius: 100px;
              }
            }

            a {
              color: $text-color;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}