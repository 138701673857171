.page-not-found-wrapper {
  color: $heading-color;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .icon {
    font-size: 5rem;
    color: $primary-color;
    padding: 20px;
  }

  .text {
    font-size: 2rem;
    padding: 20px;
  }

  a {
    color: $primary-color;
  }
}