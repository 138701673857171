.main-content-wrapper {
  background-color: $background-color-primary;
  height: 100%;
  margin-top: 100px;
}

.floating-button-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  border: 2px solid $color-violet-medium;
  border-right: 0px;
  border-top-left-radius: 20px;
  padding: 10px 20px 10px 10px;
  border-bottom-left-radius: 20px;
  margin: 0px 0px 80px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $background-color-primary;
}

@media (max-width: 994px) {
  .main-content-wrapper {
    margin-top: 56px;
  }
}