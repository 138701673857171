.profile-search {

  .job-search-container {
    background-color: #3632F1;
    color: #fff;
    padding: 20px;
    border-radius: 12px;

    .search-header {
      h3 {
        font-size: 28px;
      }

      p {
        font-size: 1rem;
      }
    }

    .search-bar {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 20px;

      .search-input {
        height: 50px;
        flex: 1;
        padding-left: 45px;
        border: none;
        border-radius: 5px 0 0 5px !important;
        font-size: 1rem;
      }

      .search-icon {
        position: absolute;
        left: 15px;
        top: 58%;
        transform: translateY(-50%);
        font-size: 24px;
        color: grey;
      }

      .sort-dropdown {
        height: 50px;
        background-color: white;
        padding: 3px;
        border-radius: 0 5px 5px 0;

        .ng-select-container {
          height: 44px;
          border: none;
          border-radius: 8px;
          background-color: #47B165;
        }

        .ng-select-placeholder {
          padding-left: 10px;
          text-align: center;
        }

        .ng-select-container .ng-value-container {
          justify-content: center;
          color: #fff;

          .ng-placeholder {
            color: #fff;
          }
        }

        .ng-arrow-wrapper,
        .ng-clear-wrapper,
        .ng-input {
          color: #fff;
        }

        .ng-arrow-wrapper {
          position: relative;

          .ng-arrow {
            border-color: white transparent transparent;
            transition: transform 0.3s;
          }
        }

        &.ng-select-opened .ng-arrow-wrapper .ng-arrow {
          border-color: transparent transparent white;
        }
      }
    }

    .results-info {
      text-align: center;

      p {
        font-size: 1rem;
        background-color: #C2C1F4;
        color: #3632F1;
        padding: 4px;
        border-radius: 8px;
        margin: 0;
      }
    }

    @media (max-width: 480px) {
      padding: 10px;

      .search-header {
        h3 {
          font-size: 1.5rem;
        }

        p {
          font-size: 0.875rem;
        }
      }

      .search-bar {
        .search-input {
          font-size: 0.875rem;
          padding-left: 40px;
        }

        .sort-dropdown {
          .ng-select-container {
            font-size: 0.875rem;
          }
        }

        .search-icon {
          left: 10px;
          font-size: 20px;
          top: 28px;
        }
      }

      .results-info {
        p {
          font-size: 0.875rem;
        }
      }
    }
  }

  .profile-card {
    display: flex;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 0 auto 20px;
    transition: all 0.3s ease;

    .profile-content {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }

    .profile-image {
      flex: 0 0 auto;
      height: auto;
      display: block;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0;
      }

      @media (min-width: 768px) {
        width: 220px;
        height: 232px;
      }
    }

    .profile-info {
      flex: 1;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      @media (min-width: 768px) {
        padding: 20px;
      }

      .profile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .profile-role {
          color: $color-green-light-parrot;
          font-weight: 500;
        }

        .profile-actions {
          display: flex;
          gap: 10px;
        }
      }

      .profile-name {
        font-weight: 600;
        margin: 8px 0;
        font-size: 1rem;

        @media (min-width: 768px) {
          font-size: 1rem;
        }
      }

      .details-and-button {
        margin-bottom: 10px;
        font-size: .8rem;
      }

      .profile-card-btns {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: flex-end;

        .btn {
          &.apply-sigin-view-btn {
            padding: 6px 16px;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }

      .profile-footer {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (min-width: 768px) {
          flex-direction: row;
        }

        .profile-location,
        .profile-skills {
          display: flex;
          align-items: center;
          gap: 5px;
          justify-content: flex-end;
          font-size: .8rem;

          mat-icon {
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
