.chat-list {
  overflow-y: auto;
  height: calc(100% - 80px);
  border: 1px solid $color-gray-light;

  .chat-item {
    display: flex;
    align-items: center;
    padding: 20px 32px;
    cursor: pointer;
    border-bottom: 1px solid $color-gray-light;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .profile-picture {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
    }

    .chat-details {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .chat-name {
        font-size: 16px;
        font-weight: 600;
        color: $color-gray-dark;
      }

      .message-value {
        color: $color-gray-medium-dark;
        font-size: 14px;
      }
    }

    .chat-meta {
      text-align: right;
      flex-shrink: 0;

      .chat-time {
        font-size: 12px;
        color: $color-gray-medium-dark;
        display: block;
      }

      .unread-count {

        color: #f57c00;
        font-size: 12px;
        margin-top: 5px;
        display: inline-block;
        text-align: center;
      }
    }
  }
}