// .profile-card-wrapper {
//   .message-input {
//     .message-bar {
//       display: flex;
//       align-items: center;
//       height: 40px;
//     }

//     .message-bar span {
//       width: 40px;
//       height: 40px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       /* Make icons circular */
//       background-color: $color-sky-blue;
//       /* Blue icon color */
//       border-bottom-right-radius: 10px;
//       border-top-right-radius: 10px;
//     }

//     .message-bar span mat-icon {
//       color: $color-white;
//       /* White icon color */
//     }

//     .input-bar {
//       flex: 1;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       border: 1px solid $grey;
//       border-radius: 10px;
//     }

//     .input-bar input {
//       flex: 1;
//       border: none;
//       outline: none;
//       background-color: transparent;
//       padding-left: 5px;
//     }

//     .input-bar span {
//       margin-left: 10px;
//       cursor: pointer;
//     }

//     @media (max-width: 768px) {
//       .message-bar span {
//         width: 30px;
//         height: 30px;
//         font-size: 18px;
//       }

//       .input-bar {
//         padding: 3px 5px;
//       }
//     }
//   }

//   .profile-pic-wrapper {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//   }

//   .profile-name {
//     font-weight: 500;
//     text-align: center;
//     font-size: 1.2rem
//   }

//   .profile-footer-btns {
//     flex-direction: row;
//     display: flex;
//     justify-content: space-between;

//     .profile-accept-reject {
//       background: #eeeeff;
//       border-radius: 5px;
//       font-size: 14px;
//       padding: 5px 10px;
//       display: inline-block;
//       color: #0066ff;
//       transition: ease all 0.5s;
//       -webkit-transition: ease all 0.5s;
//       -ms-transition: ease all 0.5s;
//       line-height: normal;
//       display: inline-block;
//       margin-bottom: 12px;
//     }
//   }
// }

// New styles
.jobs-created-container {
  .profile-card-wrapper {
    .profile-card {
      display: flex;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      margin: 0 auto 20px;
      transition: all 0.3s ease;

      .profile-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (min-width: 768px) {
          flex-direction: row;
        }
      }

      .profile-image {
        flex: 0 0 auto;
        height: auto;
        display: block;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0;
        }

        @media (min-width: 768px) {
          height: 232px;
          width: 220px;
        }
      }

      .profile-info {
        flex: 1;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @media (min-width: 768px) {
          padding: 20px;
        }

        .profile-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .profile-role {
            color: $color-green-light-parrot;
            font-weight: 500;
          }

          .profile-actions {
            display: flex;
            gap: 10px;
          }
        }

        .profile-name {
          font-weight: 600;
          margin: 8px 0;
          font-size: 1rem;

          @media (min-width: 768px) {
            font-size: 1rem;
          }
        }

        .details-and-button {
          margin-bottom: 10px;
          font-size: .8rem;
        }

        .profile-card-btns {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          justify-content: flex-end;

          .btn {

            &.apply-sigin-view-btn,
            &.btn-danger {
              padding: 6px 16px;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }

        .profile-footer {
          display: flex;
          flex-direction: column;

          @media (min-width: 768px) {
            flex-direction: row;
            gap: 10px;
          }

          .profile-location,
          .profile-skills {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: flex-start;
            font-size: .8rem;

            mat-icon {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}