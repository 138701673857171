.contact-us-wrapper {
  padding: 40px 0px;

  .contact-info {

    .section-title {


      .title {
        font-size: 48px;
        line-height: 54px;
        font-family: "Barlow", sans-serif;
        font-weight: 500;
        color: $text-color;
        position: relative;
        padding-left: 45px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          background: $color-violet-medium;
          height: 150px;
          width: 4px;
          transform: translateY(-50%);
        }
      }
    }

    ul {
      padding: 0;
      padding-top: 15px;
      list-style: none;
      margin: 0;

      li {
        margin-top: 15px;
        padding-right: 65px;

        .contact-info-item .contact-info-text .title {
          font-size: 24px;
          line-height: 30px;
          color: $text-color;
        }

      }
    }
  }


  .contact-us-wrap {
    background-color: $background-color-secondary ;
    border-radius: 10px;

    .heading-wrap {
      margin-bottom: 30px;
      text-align: center !important;

      .sub-title {
        font-size: 14px;
        line-height: 30px;
        font-family: "Barlow", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        color: $color-violet-medium;
      }

      .title {
        font-size: 32px;
        line-height: 48px;
        font-weight: 600;
        color: $text-color;
      }
    }

    .contact-us-form {
      .reset-button {
        margin-left: 10px;
      }
    }
  }

}

@media (max-width: 994px) {
  .contact-us-wrapper {
    flex-direction: column-reverse;
  }
}