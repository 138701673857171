.wrapper {
     .user-profile-items {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .profile-actions {}

          .profile-actions,
          .profile-btns {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               align-items: center;
          }
     }
}