.physical-attributes-section {

     .quick-links-section {
          .info-label {
               p {
                    display: flex;
                    width: 100%;

                    span:first-child {
                         flex: 0 0 75%;
                    }

                    a {
                         text-decoration: none;
                    }
               }
          }
     }

     // .attributes-section {
     //      .attribute-type {
     //           p {
     //                display: flex;
     //                width: 100%;

     //                strong {
     //                     flex: 0 0 35%;
     //                }
     //           }
     //      }
     // }
}