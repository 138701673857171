.projects-dashboard-wrapper {
  height: 100%;
  min-height: 400px;
  position: relative;

  .project-create-btn {
    padding: 20px;
    text-align: center;
  }

  .projects-section {
    .projects-content {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
    }

    .projects-list {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      height: calc(100% - 40px);
      justify-content: center;
      padding: 20px;

      .create-project-shortlist {
        cursor: pointer;
        height: 40px;
        color: blue;
        text-align: center;
        line-height: 40px;
        text-decoration: underline;
        font-size: 20px;
      }

      .edit-delete-icons {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.project-shortlist-icons {
          flex-direction: row;
          justify-content: space-between;

          .mat-icon {
            font-size: 18px;
          }
        }
      }

      .project-list-item {
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        padding: 8px;

        &:hover {
          box-shadow: rgba(174, 169, 255, 0.2) 0px 7px 29px 0px;
        }

        &.selected {
          background-color: rgba(185, 185, 185, 0.2);
        }

        .icon {
          &.active {
            color: $primary-color !important;
          }
        }

        &.projects {
          .project-description {
            font-size: 10px;
          }
        }

      }

      .project-shotlist-profile {

        .profile-item {
          display: flex;
          flex-direction: row;
          border: 1px solid lightgray;
          border-radius: 20px;
          height: 100%;


          .profile-image {
            flex-shrink: 0;
            width: 94px;
            height: 100%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 12px;
            }
          }

          .profile-details {
            flex: 1;
            padding: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .profile-name {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              h6 {
                margin: 0;
                word-break: break-word;
              }

              .mat-icon {
                overflow: visible;
              }
            }

            .apply-sigin-view-btn {
              padding: 0;
            }
          }
        }

        .shortlist-profile-btns {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }
}