.photo-links-wrapper {
  .photos-upload-div {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;

    &.nav-profile {
      height: 40px;
      width: 40px;
    }

    img,
    video {
      position: absolute;
      height: 200px;
      width: 200px;
      object-fit: cover;

      &.nav-profile {
        height: 40px;
        width: 40px;
      }
    }

    .close-icon {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      margin: 0px 5px;
      font-weight: 500;
    }

    .label-profile-upload {
      height: 200px;
      width: 200px;
      background-image: url('/assets/images/profile-avatar.png');
      background-size: cover;
      color: #ff7575;

      &.nav-profile {
        height: 40px;
        width: 40px;
      }

      &.no-pic {
        text-align: center;
      }
    }



    .label-portfolio-upload {
      height: 200px;
      width: 200px;

      background-size: contain;
      color: #ff7575;
      object-fit: contain;

      &.no-pic {
        text-align: center;
      }
    }

    .label-video-upload {
      height: 200px;
      width: 200px;
      background-image: url('/assets/images/video-upload.png');
      background-size: cover;
      color: #ff7575;

      &.no-pic {
        text-align: center;
      }
    }

    .label-audio-upload {
      height: 200px;
      width: 200px;
      background-image: url('/assets/images/audio-upload.png');
      background-size: cover;
      background-position: center;
      color: #ff7575;

      &.no-pic {
        text-align: center;
      }
    }

    .input-profile-upload {
      display: none;
    }

    .profile-pic {
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      border: 4px solid $grey-opacity;

      &.nav-profile {
        border: 2px solid $grey-opacity;
      }
    }

    .applied-profile-pic {
      background-size: cover;
      background-position: center;
      border: 4px solid $grey-opacity;
    }

    .img-portfolio-upload {
      object-fit: contain;
    }
  }

  .upload-div-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .display-mode {
    .photos-wrapper {
      width: 100%;
      height: 200px;

      .photo-display {
        height: 200px;
        width: 200px;
      }
    }
  }
}