.independent-profile-wrapper-edit-mode {

     // .upload-div {
     //   justify-content: flex-start;
     // }
     .independent-bg-profile-image {
          background-color: #e5e5f7;
          background-image: radial-gradient(#999df6 0.65px, transparent 0.65px), radial-gradient(#999df6 0.65px, #e5e5f7 0.65px);
          background-size: 26px 26px;
          background-position: 0 0, 13px 13px;
          padding: 10px;
          margin: 10px 0px;

          .upload-div {
               width: 100%;
               flex-direction: row;
               justify-content: flex-start;

               .action-btns-div {
                    z-index: 9999;
               }
          }
     }
}

.independent-profile-wrapper-display-mode {
     .edit-mode-icon {
          display: flex;
          justify-content: flex-end;
     }

     .form-group {
          margin-bottom: 15px;
     }

     .heading-wrapper {
          margin-bottom: 20px;
     }

     .independent-profile-data-section {
          display: flex;

          @media (max-width: 768px) {
               flex-direction: column;
          }

          .profile-image {
               img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
               }
          }
     }
}